<template>
  <v-row>
    <v-col
      cols="6"
      style="position:relative; background-color:#ddd; color:#333f48;"
    >
      <v-row>
        <v-col cols="7" v-if="today">
          <template v-if="isMobilelow">
            <img
              class="weatherIconSmall"
              id="icon0"
              :src="getIcoUrl0(today.ico)"
            />
          </template>
          <template v-else>
            <img class="weatherIcon" id="icon0" :src="getIcoUrl0(today.ico)" />
          </template>
        </v-col>
        <v-col cols="5" v-if="today" id="temp0">
          <template v-if="isMobilelow">
            <div class="weatherTempSmall">{{ today.temp[0] }}</div>
          </template>
          <template v-else>
            <div class="weatherTemp">{{ today.temp[0] }}</div>
          </template>
        </v-col>
      </v-row>
      <v-row class="cityName" id="city">
        <v-spacer></v-spacer>
        {{ city }}
      </v-row>
    </v-col>

    <template v-if="isMobile">
      <v-col v-if="day1" cols="2" style="position: relative;">
        <v-row class="forecastDaysSmall" id="day1">
          {{ d1 }}
        </v-row>
        <v-row>
          <img
            class="forecastIconsSmall"
            id="icon1"
            :src="getIcoUrl(day1.ico)"
          />
        </v-row>
        <v-row class="forecastMin" id="temp1">
          {{ getTemp2(day1.temp) }}
        </v-row>
      </v-col>
      <v-col v-if="day2" cols="2" style="position: relative">
        <v-row class="forecastDaysSmall" id="day2">
          {{ d2 }}
        </v-row>
        <v-row>
          <img
            class="forecastIconsSmall"
            id="icon2"
            :src="getIcoUrl(day2.ico)"
          />
        </v-row>
        <v-row class="forecastMin" id="temp2">
          {{ getTemp2(day2.temp) }}
        </v-row>
      </v-col>
      <v-col v-if="day3" cols="2" style="position: relative">
        <v-row class="forecastDaysSmall" id="day3">
          {{ d3 }}
        </v-row>
        <v-row>
          <img
            class="forecastIconsSmall"
            id="icon3"
            :src="getIcoUrl(day3.ico)"
          />
        </v-row>
        <v-row class="forecastMin" id="temp3">
          {{ getTemp2(day3.temp) }}
        </v-row>
      </v-col>
    </template>
    <template v-else>
      <v-col v-if="day1" cols="2" style="position: relative;">
        <v-row class="forecastDays" id="day1">
          {{ d1 }}
        </v-row>
        <v-row>
          <img class="forecastIcons" id="icon1" :src="getIcoUrl(day1.ico)" />
        </v-row>
        <v-row class="forecastMin" id="temp1">
          {{ getTemp(day1.temp) }}
        </v-row>
      </v-col>
      <v-col v-if="day2" cols="2" style="position: relative">
        <v-row class="forecastDays" id="day2">
          {{ d2 }}
        </v-row>
        <v-row>
          <img class="forecastIcons" id="icon2" :src="getIcoUrl(day2.ico)" />
        </v-row>
        <v-row class="forecastMin" id="temp2">
          {{ getTemp(day2.temp) }}
        </v-row>
      </v-col>
      <v-col v-if="day3" cols="2" style="position: relative">
        <v-row class="forecastDays" id="day3">
          {{ d3 }}
        </v-row>
        <v-row>
          <img class="forecastIcons" id="icon3" :src="getIcoUrl(day3.ico)" />
        </v-row>
        <v-row class="forecastMin" id="temp3">
          {{ getTemp(day3.temp) }}
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import {
  computed,
  reactive,
  toRefs,
  watch,
  onMounted,
  ref
} from "@vue/composition-api";
import { PORTAL_CONFIG } from "../../config";
const dayNames = [
  "Dim",
  "Lun",
  "Mar",
  "Mer",
  "Jeu",
  "Ven",
  "Sam",
  "Dim",
  "Lun",
  "Mar",
  "Mer"
];

const today = new Date().getDay();
const today1 = today + 1;
const today2 = today + 2;
const today3 = today + 3;

export default {
  name: "Weather",
  props: {
    traficData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lh
      );
    },
    isMobilelow() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lg
      );
    }
  },
  setup(props) {
    const state = reactive({
      today: {},
      day1: {},
      day2: {},
      day3: {},
      d1: dayNames[today1],
      d2: dayNames[today2],
      d3: dayNames[today3]
    });
    const weatherData = computed(() => {
      return props.traficData && props.traficData.meteo
        ? props.traficData.meteo
        : ref([]);
    });

    const city = computed(() =>
      props.traficData && props.traficData.general
        ? props.traficData.general.city
        : null
    );

    onMounted(() => {
      if (weatherData.value) {
        state.today = weatherData.value.now;
        state.day1 = weatherData.value.J1;
        state.day2 = weatherData.value.J2;
        state.day3 = weatherData.value.J3;
      }
    });

    watch(weatherData, weatherData => {
      if (weatherData) {
        state.today = weatherData.now;
        state.day1 = weatherData.J1;
        state.day2 = weatherData.J2;
        state.day3 = weatherData.J3;
      }
    });

    const getTemp = temp => (temp ? temp[0] + " à " + temp[1] + "°" : null);

    const getTemp2 = temp =>
      temp ? Math.round((temp[0] + temp[1]) / 2) : null;

    const getIcoUrl0 = icon =>
      `${PORTAL_CONFIG.SERVER_WEB}/img/meteo/now/${icon}.svg`;

    const getIcoUrl = icon =>
      `${PORTAL_CONFIG.SERVER_WEB}/img/meteo/3d/${icon}.svg`;

    return {
      ...toRefs(state),
      city,
      getTemp,
      getTemp2,
      getIcoUrl0,
      getIcoUrl
    };
  }
};
</script>

<style scoped>
.cityName {
  margin-top: -1.5em;
  padding-right: 0.5em;
  text-transform: uppercase;
}
.weatherIcon {
  width: 6em;
  height: 6em;
  margin-top: -2em;
  margin-bottom: -0.8em;
  margin-left: -1em;
}
.weatherIconSmall {
  width: 4em;
  height: 4em;
  margin-top: -0.5em;
  margin-bottom: -0.4em;
  margin-left: -1em;
}
.weatherTemp {
  font-size: 2em;
  transition: 0.2s ease-out;
}
.weatherTempSmall {
  font-size: 2em;
  transition: 0.2s ease-out;
  margin-left: -0.5em;
}
.forecastDays {
  margin-top: -0.4em;
  font-size: 1em;
  padding-left: 0.5em;
}
.forecastDaysSmall {
  margin-top: -0.4em;
  font-size: 0.8em;
  padding-left: 0.5em;
}
.forecastIcons {
  position: relative;
  margin-top: -0.2em;
  width: 3em;
  height: 3em;
}
.forecastIconsSmall {
  position: relative;
  margin-top: -0.2em;
  width: 3em;
  height: 3em;
}
.forecastMin {
  position: relative;
  color: #b3b3b3;
  margin-top: -0.2em;
  font-size: 0.8em;
  padding-left: 0.5em;
}
</style>
