import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../lang/en";
import fr from "../lang/fr";

Vue.use(VueI18n);

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: "fr",
  messages: {
    en,
    fr
  } // set locale
});
