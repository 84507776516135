<template>
  <div style="height: 330px; width: 100%">
    <l-map
      v-if="showMap"
      :zoom="zoom2"
      :maxZoom="22"
      :center="center"
      :options="mapOptions"
      style="height: 100%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      :key="itemkey"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-image-overlay v-if="isMap" :url="url_map" :bounds="bounds" />
      <l-marker
        v-for="(item, key) in listData"
        :lat-lng="item.location"
        :key="key"
        @click="goToLink(item.link)"
      >
        <l-icon
          v-if="isOpe"
          :icon-size="Size"
          :icon-anchor="Anchor"
          :icon-url="url_icon(item.states_id)"
        />
        <l-tooltip
          :options="{
            permanent: true,
            interactive: true,
            direction: 'bottom',
            offset: [0, 2]
          }"
        >
          <h3>
            {{ item.name }}
          </h3>
        </l-tooltip>
      </l-marker>
    </l-map>
    <l-map v-else>
      <br /><br /><br /><br /><br /><br /><br /><br />Pas d'élément, rien à
      afficher
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LImageOverlay,
  LIcon
} from "vue2-leaflet";
import { mapGetters } from "vuex";
import { PORTAL_CONFIG } from "../config";

export default {
  name: "MapView",
  props: ["lat", "lng", "name", "states_id", "list"],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LImageOverlay,
    LTooltip,
    LIcon
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      bounds: [
        [0, 0],
        [0, 0]
      ],
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      zoom2: 11.5,
      currentCenter: null,
      showParagraph: false,
      isMap: "",
      mapOptions: {
        zoomSnap: 0.5
      },
      itemkey: 1,
      Size: [60, 60],
      Anchor: [30, 60],
      hasData: false,
      leafleterrortest: 0
    };
  },
  created() {
    this.loadModulesData();
  },
  computed: {
    ...mapGetters(["getUUID", "getAssets"]),
    url_map() {
      return (
        PORTAL_CONFIG.SERVER_WEB +
        "/" +
        PORTAL_CONFIG.APP_NAME +
        "/" +
        PORTAL_CONFIG.CLIENT_DATA +
        "/" +
        this.params.id +
        "/map.png"
      );
    },
    currentAsset() {
      const assetId = this.$route.params.id;
      const asset = this.getAssets.find(asset => {
        return +asset.id === +assetId;
      });

      return asset ? asset : null;
    },
    params() {
      return {
        id: this.$route.params.id
      };
    },
    showMap() {
      if (this.list.length !== 0) {
        return true;
      }
      return false;
    },
    listData() {
      return this.list.map(el => {
        return {
          name: el.name,
          location: latLng(el.lat, el.lng),
          link: el.link,
          states_id: el.states_id
        };
      });
    },
    location() {
      const lat = this.list && this.list[0] ? this.list[0].lat : 0;
      const lng = this.list && this.list[0] ? this.list[0].lng : 0;
      return latLng(lat, lng);
    },
    center() {
      if (this.list[0]) {
        let maxlat = this.list[0].lat;
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].lat > maxlat) {
            maxlat = this.list[i].lat;
          }
        }
        let minlat = this.list[0].lat;
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].lat < minlat) {
            minlat = this.list[i].lat;
          }
        }
        let maxlng = this.list[0].lng;
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].lng > maxlng) {
            maxlng = this.list[i].lng;
          }
        }
        let minlng = this.list[0].lng;
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].lng < minlng) {
            minlng = this.list[i].lng;
          }
        }
        if (this.list.length == 1) {
          return this.location;
        }
        let midlat = (parseFloat(maxlat) + parseFloat(minlat)) / 2;
        let midlng = (parseFloat(maxlng) + parseFloat(minlng)) / 2;
        return latLng(midlat, midlng);
      } else {
        return latLng(45, 7);
      }
    },
    isOpe() {
      if (this.list[0].states_id === undefined) {
        return false;
      } else {
        return true;
      }
    },
    zoom() {
      if (this.list[0]) {
        let maxlat = this.list[0].lat;
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].lat > maxlat) {
            maxlat = this.list[i].lat;
          }
        }
        let minlat = this.list[0].lat;
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].lat < minlat) {
            minlat = this.list[i].lat;
          }
        }
        if (this.list.length == 1) {
          return 13;
        } else {
          let diflat = parseFloat(maxlat) - parseFloat(minlat);
          if (diflat < 0.0009) {
            return 17.5;
          } else if (diflat < 0.01) {
            return 15;
          } else if (diflat < 0.12) {
            return 14;
          } else if (diflat < 1.2) {
            return 8;
          } else if (diflat < 2.5) {
            return 7;
          } else if (diflat < 4.3) {
            return 6;
          } else {
            return 5.2;
          }
        }
      } else {
        return 5.2;
      }
    }
  },
  methods: {
    url_icon(state) {
      return PORTAL_CONFIG.SERVER_WEB + "/img/map/" + state + ".png";
    },
    loadModulesData() {
      let id = this.$route.params.id;
      if (
        this.$route.name == "assets.show" ||
        this.$route.name == "lots.show"
      ) {
        if (id) {
          fetch(
            `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_DATA}/maps.php?entity=${id}`
          )
            .then(response => {
              return response.json();
            })
            .then(data => {
              if (data) {
                this.mapOverlay(data);
              }
            })
            .catch(function() {
              // catch any errors
            });
        }
      } else {
        this.zoom2 = this.zoom;
      }
    },
    mapOverlay(d) {
      this.currentAsset.altitude
        ? (this.zoom2 = parseInt(this.currentAsset.altitude))
        : (this.zoom2 = 11.5);
      if (this.leafleterrortest == 0 && d.param.map === "oui") {
        this.bounds = d.param.coords;
        this.isMap = true;
        this.leafleterrortest = 1;
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    goToLink(link) {
      if (link == undefined) {
        link = null;
      } else if (typeof link !== "string") {
        if (
          this.$route.name !== "assets.show" ||
          this.$route.name !== "lots.show"
        )
          this.$router.push(link);
      } else {
        let alignement = this.$vuetify.breakpoint.width - 500;
        const windowFeatures = "left=" + alignement + ",width=500,height=800";
        window.open(link, "_blank", windowFeatures);
      }
    }
  },
  watch: {
    params() {
      this.loadModulesData();
    },
    list: {
      deep: true,
      handler: function() {
        this.itemkey++;
      }
    }
  }
};
</script>
