// Utils
import { getObjectValueByPath, number_format } from "../utils";

export default {
  name: "c-row",

  functional: true,

  props: {
    headers: Array,
    item: Object,
    rtl: Boolean,
    ignoreNullVal: {
      type: Boolean,
      default: false
    }
  },

  render(h, { props, slots, data }) {
    //console.log(data, props);

    if (
      !props.ignoreNullVal &&
      !getObjectValueByPath(props.item, props.headers[0].value)
    )
      return null;

    const computedSlots = slots();

    const columns = props.headers.map(header => {
      const children = [];
      let value = getObjectValueByPath(props.item, header.value);
      if (header.isNumber && !isNaN(value)) {
        if (value >= 0) {
          value = number_format(value, 0, ",", " ");
        } else {
          value = "< " + number_format(-value, 0, ",", " ") + " >";
        }
      }

      const slotName = header.value;
      const scopedSlot = data.scopedSlots && data.scopedSlots[slotName];
      const regularSlot = computedSlots[slotName];

      if (scopedSlot) {
        children.push(scopedSlot({ item: props.item, header, value }));
      } else if (regularSlot) {
        children.push(regularSlot);
      } else {
        children.push(value == null ? value : String(value));
      }

      const textAlign = `text-${header.align || "start"}`;

      return h(
        "td",
        {
          class: {
            [textAlign]: true,
            "v-data-table__divider": header.divider
          }
        },
        children
      );
    });

    return h("tr", data, columns);
  }
};
