import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Toast from "vue-toastification";
import store from "./store";
import "vue-toastification/dist/index.css";
import vuetify from "./plugins/vuetify";
import VueCompositionApi from "@vue/composition-api";
import Notify from "./plugins/notification";
import DatetimePicker from "vuetify-datetime-picker";
import VueFlags from "@growthbunker/vueflags";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import { number_format } from "./utils";

//import "vuetify-datetime-picker/src/stylus/main.styl";
import "leaflet/dist/leaflet.css";
import "tiptap-vuetify/dist/main.css";
import "./components/carto/_globals";
import Row from "./components/Row";
import { i18n } from "./plugins/i18n";

Vue.use(DatetimePicker);
Vue.use(Notify);
Vue.use(VueCompositionApi);
Vue.use(VueFlags, {
  iconPath: "/flags/"
});
Vue.config.productionTip = false;
Vue.component(Row.name, Row);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "md"
});

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 6,
  newestOnTop: true
  /*
  filterToasts: toasts => {
    // Keep track of existing types
    const types = {};
    return toasts.reduce((aggToasts, toast) => {
      // Check if type was not seen before
      if (!types[toast.type]) {
        aggToasts.push(toast);
        types[toast.type] = true;
      }
      return aggToasts;
    }, []);
  }
  //https://vue-toastification.maronato.dev*/
};

Vue.use(Toast, options);

Vue.filter("number_format", value => {
  return number_format(value, 0, ",", " ");
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
