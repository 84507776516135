export const notification = {
  state: {
    nbNewIncident: 0,
    nbNewLgt: 0,
    nbNewKey: 0
  },

  actions: {
    incrementIncident({ commit }) {
      commit("incrementIncident", 1);
    },
    decrementIncident({ commit }) {
      commit("decrementIncident", 1);
    },
    incrementLgt({ commit }) {
      commit("incrementLgt", 1);
    },
    decrementLgt({ commit }) {
      commit("decrementLgt", 1);
    },
    incrementKey({ commit }) {
      commit("incrementKey", 1);
    },
    decrementKey({ commit }) {
      commit("decrementKey", 1);
    }
  },

  mutations: {
    incrementIncident(state, nbNewIncident) {
      state.nbNewIncident += nbNewIncident;
      localStorage.setItem("nbNewIncident", state.nbNewIncident);
    },
    decrementIncident(state, nbNewIncident) {
      state.nbNewIncident -= nbNewIncident;
      localStorage.setItem("nbNewIncident", state.nbNewIncident);
    },
    incrementLgt(state, nbNewLgt) {
      state.nbNewLgt += nbNewLgt;
      localStorage.setItem("nbNewLgt", state.nbNewLgt);
    },
    decrementLgt(state, nbNewLgt) {
      state.nbNewLgt -= nbNewLgt;
      localStorage.setItem("nbNewLgt", state.nbNewLgt);
    },
    incrementKey(state, nbNewKey) {
      state.nbNewKey += nbNewKey;
      localStorage.setItem("nbNewKey", state.nbNewKey);
    },
    decrementKey(state, nbNewKey) {
      state.nbNewKey -= nbNewKey;
      localStorage.setItem("nbNewKey", state.nbNewKey);
    }
  },
  getters: {
    getNewIncidentCount(state) {
      return state.nbNewIncident;
    },
    getNewLgtCount(state) {
      return state.nbNewLgt;
    },
    getNewKeyCount(state) {
      return state.nbNewKey;
    }
  }
};
