import axios from "axios";
import { PORTAL_CONFIG } from "../config";

const baseApiUrl = `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`;
// axios.defaults.baseURL = `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`;

export default {
  // eslint-disable-next-line no-unused-vars
  reservationCheckoutR2R(
    sessionToken,
    reservationItemId,
    statutLgt,
    lgt_name,
    entityn4,
    entityn5,
    resaid
  ) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "modStatutLgt");
    formData.append(
      "data",
      JSON.stringify({
        Reservation_itemID: reservationItemId,
        statut: statutLgt,
        LgtName: lgt_name,
        mEntity: entityn4,
        entity: entityn5,
        resaId: resaid,
        closeResa: "1"
      })
    );

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      true
    );
    xhr.send(formData);
    return 1;
  },
  changeR2R(
    sessionToken,
    itemType,
    itemTypeID,
    statutLgt,
    Lgtname,
    message,
    entityn4
  ) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "modStatutLgt");
    formData.append(
      "data",
      JSON.stringify({
        itemtype: "PluginGenericobject" + itemType,
        itemtypeID: itemTypeID,
        statut: statutLgt,
        LgtName: Lgtname,
        msg: message,
        mEntity: entityn4,
        newstatut: "forced"
      })
    );

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      true
    );
    xhr.send(formData);
    return 1;
  },
  changeAllR2R(sessionToken, data) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "modStatutLgtMulti");
    formData.append("data", data);

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      true
    );
    xhr.send(formData);
    return 1;
  },
  async notifyLgt(sessionToken, data) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "notifyLgt");
    formData.append("data", data);

    return axios.post(baseApiUrl, formData);
  },
  reservationUpdateMail(sessionToken, resaid, eMail) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "updateResa");
    formData.append(
      "data",
      JSON.stringify({
        resaId: resaid,
        email: eMail
      })
    );

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      true
    );
    xhr.send(formData);
    return 1;
  },
  reservationUpdateDate(sessionToken, resaid, dateEnd) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "updateResa");
    formData.append(
      "data",
      JSON.stringify({
        resaId: resaid,
        end: dateEnd
      })
    );

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      true
    );
    xhr.send(formData);
    return 1;
  },
  resendInfo(sessionToken, resas, fromName) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "sendInfoLgtMulti");
    formData.append(
      "data",
      JSON.stringify({
        resaIds: resas,
        from: fromName
      })
    );

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      true
    );
    xhr.send(formData);
    return 1;
  },
  clefUpdate(sessionToken, clefid, value, name, back, status) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "myKey");
    formData.append(
      "data",
      JSON.stringify({
        updatekey: clefid,
        name: value,
        back: value,
        statut: value,
        onlyname: name,
        onlyback: back,
        onlystatut: status
      })
    );

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      true
    );
    xhr.send(formData);
    return 1;
  },
  clefsPresta(sessionToken, clefid, qui, back, who) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "traceKey");
    if (who == 2) {
      formData.append(
        "data",
        JSON.stringify({
          keys: clefid,
          who: qui,
          retour: back,
          newpeople: 1,
          who_code: who
        })
      );
    } else {
      formData.append(
        "data",
        JSON.stringify({
          keys: clefid,
          who: qui,
          newpeople: 1,
          who_code: who
        })
      );
    }

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      true
    );
    xhr.send(formData);
    return 1;
  },
  getKeys(sessionToken, ent) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "getKeyStatus");
    formData.append(
      "data",
      JSON.stringify({
        //itemtype: "PluginGenericobject" + itemType,
        //itemtypeID: itemTypeID,
        //statut: statutLgt,
        //LgtName: Lgtname,
        //msg: message,
        entity: ent
      })
    );

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/api.php`,
      false
    );
    xhr.send(formData);
    return xhr.responseText;
  },
  getKetchup(sessionToken, ent) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "getKetchup");
    formData.append(
      "data",
      JSON.stringify({
        entity: [ent]
      })
    );

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/apiportail.php`,
      false
    );
    xhr.send(formData);
    return xhr.responseText;
  },
  async getSejour(sessionToken) {
    const formData = new FormData();
    formData.append("session", sessionToken);
    formData.append("action", "getSejour");
    formData.append("data", "");

    return axios.post(baseApiUrl, formData);
  }
  /*,
  async getSejour2(sessionToken) {
    axios.defaults.headers["Session"] = sessionToken;
    let url = baseApiUrl + "?action=getSejour";
    return axios.get(url);
  }*/
};
