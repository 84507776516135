import TICKET_API from "../api/ticket";

export const itemType = {
  state: {
    itemTypes: [],
    list: [],
    itemLoadCount: 0,
    itemCount: 0,
    noData: false
  },
  actions: {
    async loadItemType({ commit, state }, itemType) {
      return new Promise(async (resolve, reject) => {
        const index = state.list.findIndex(el => el === itemType);
        if (index >= 0) {
          resolve();
        } else {
          commit("SET_ITEM", itemType);
          commit("COUNT_ITEM");
          try {
            const { data } = await TICKET_API.getItemType(itemType);
            commit("SET_ITEM_TYPE", { itemType, data });
            commit("COUNT_ITEM_LOAD");
            return resolve(data);
          } catch (error) {
            reject(error);
          }
        }
      });
    },
    emptyItemsFound({ commit }) {
      commit("NO_DATA", true);
    },
    resetItemData({ commit }) {
      return new Promise(resolve => {
        commit("resetItemsTypesData");
        resolve("done");
      });
    }
  },
  mutations: {
    SET_ITEM_TYPE(state, item) {
      state.itemTypes.push(item);
    },
    SET_ITEM(state, item) {
      state.list.push(item);
    },
    COUNT_ITEM(state) {
      state.itemCount++;
    },
    COUNT_ITEM_LOAD(state) {
      state.itemLoadCount++;
    },
    NO_DATA(state, val) {
      state.noData = val;
    },
    resetItemsTypesData(state) {
      (state.itemTypes = []),
        (state.list = []),
        (state.itemLoadCount = 0),
        (state.itemCount = 0),
        (state.noData = false);
    }
  },
  getters: {
    itemTypes: state => state.itemTypes,
    getTypeName: state => (itemType, itemTypeId) => {
      const item = state.itemTypes.find(el => el.itemType == itemType);
      const iType = item ? item.data.find(el => +el.id === +itemTypeId) : null;
      return iType ? iType.name : "Sans Equipement";
    },
    getEquipmentByType: state => itemType =>
      state.itemTypes.find(el => el.itemType == itemType),
    itemLoaded: state =>
      (state.itemCount > 0 && state.itemCount === state.itemLoadCount) ||
      state.noData
  }
};
