import { render, staticRenderFns } from "./NotificationType2.vue?vue&type=template&id=f0cd34fc&scoped=true&"
import script from "./NotificationType2.vue?vue&type=script&lang=js&"
export * from "./NotificationType2.vue?vue&type=script&lang=js&"
import style0 from "./NotificationType2.vue?vue&type=style&index=0&id=f0cd34fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0cd34fc",
  null
  
)

export default component.exports