import store from "../store";
import guest from "./before/guest";
import auth from "./before/auth";

export default (to, from, next) => {
  if (to.matched.some(record => record.meta.directLink || to.meta.guestGuard)) {
    if (
      to.matched.some(record => record.meta.directLink) &&
      !to.matched.some(record => record.meta.fromEcran)
    ) {
      store.dispatch("logout");
    }
    if (to.matched.some(record => record.meta.fromEcran)) {
      store.dispatch("guestUser", true);
    } else {
      store.dispatch("guestUser");
    }
  } else {
    store.commit("setGuest", false);
  }
  if (store.state.auth.isAuth) {
    auth(to, from, next);
  } else {
    guest(to, from, next);
  }
};
