<template>
  <v-app-bar color="#333F48" dense dark bottom app clipped-left height="96">
    <v-row class="d-flex align-center fill-height">
      <v-col cols="3" style="position: relative;" class="fill-height">
        <clock></clock>
        <v-divider
          class="mx-4"
          style="position: absolute; right: 0; top: 0"
          vertical
        ></v-divider>
      </v-col>
      <v-col cols="7" style="position: relative;" class="fill-height">
        <transport :trafic-data="traficData"></transport>
      </v-col>
      <v-col cols="2" class="pa-0 d-flex justify-end fill-height">
        <weather :trafic-data="traficData"></weather>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import Clock from "./Clock";
import Transport from "./Transport";
import Weather from "./Weather";
import { TRANSPORT_DATE_TIMEOUTE } from "../../utils";
import { PORTAL_CONFIG } from "../../config";

export default {
  name: "Footer",
  components: { Weather, Transport, Clock },
  data() {
    return {
      icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
      traficData: {}
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    loadData() {
      let id = this.$route.params.id;
      fetch(
        `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_DATA}/${id}/data.php`
      )
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.traficData = data;
        });
    }
  },
  mounted() {
    if (this.id) {
      this.loadData();
    }
    setInterval(() => {
      this.loadData();
    }, TRANSPORT_DATE_TIMEOUTE);
  },
  watch: {
    id() {
      if (this.id) {
        this.loadData();
      }
    }
  }
};
</script>

<style scoped>
.clock-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
