export const langs = [
  {
    key: "french",
    local: "fr",
    icon: "fr"
  },
  {
    key: "english",
    local: "en",
    icon: "us"
  }
];
