export const auth = {
  state: {
    isAuth: !!localStorage.getItem("session_token")
  },

  actions: {
    login({ commit }) {
      commit("login");
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        resolve("logout");
      });
    }
  },

  mutations: {
    login(state) {
      state.isAuth = true;
    },
    logout(state) {
      state.isAuth = false;
      //localStorage.setItem("isAuth", false);
      localStorage.removeItem("session_token");
    }
  },
  getters: {
    isAuth(state) {
      return state.isAuth;
    }
  }
};
