import axios from "../plugins/axios";
import { PORTAL_CONFIG } from "../config";

export default {
  initSession: function(user_token) {
    return axios.get(
      `/initSession/?app_token=${PORTAL_CONFIG.APP_TOKEN}&user_token=${user_token}`
    );
  },
  loginUser(login, password) {
    axios.defaults.headers.common["Authorization"] = `Basic ${btoa(
      login + ":" + password
    )}`;
    return axios.get(`/initSession/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  killSession: function() {
    return axios.get(`killSession/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  }
};

/****
 * import axios from "../plugins/axios";
import { PORTAL_CONFIG } from "../config";

function initSession(){
  return axios.get(`/initSession/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
}

export default {
  initSession: function(user_token) {
    axios.defaults.headers["Authorization0"] = `user_token ${user_token};`;
    return initSession();
  },
  loginUser(login, password) {
    axios.defaults.headers["Authorization0"] = `Basic ${login} ${password}`;
    return initSession();
  }
};
*/
