import Vue from "vue";
import Vuetify from "vuetify/lib";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { PORTAL_COLOR } from "../config";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "md"
  },
  theme: {
    primary: PORTAL_COLOR.COLOR_PRIMARY,
    secondary: "#333F48",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    drawer: PORTAL_COLOR.COLOR_DRAWER,
    themes: {
      light: {
        primary: PORTAL_COLOR.COLOR_PRIMARY,
        secondary: "#333F48",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        footerTextColor: "#333F48",
        drawer: PORTAL_COLOR.COLOR_DRAWER
      }
    },
    options: {
      customProperties: true
    }
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280,
      lh: 1450,
      tv: 1920
    }
  }
});

export default vuetify;
