<template>
  <div class="notification-container">
    <div class="title">{{ title }}</div>
    <p>{{ text }}</p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped>
.notification-container {
  margin: 0 12px;
}

p {
  margin-top: 5px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
}
</style>
