/* eslint-disable prettier/prettier */
import SESSION_API from "../api/session";
import axios from "../plugins/axios";
import { loadStates } from "../utils";

export const session = {
  state: {
    session_token: localStorage.getItem("session_token"),
    user_api_token: localStorage.getItem("user_token"),
    sessionInit: 0,
    sessionLoadingStatus: 0
  },

  actions: {
    loginUser({ commit, dispatch }, userData) {
      return new Promise((resolve, reject) => {
        SESSION_API.loginUser(userData.username, userData.password)
          .then(response => {
            if (response.data.session_token) {
              commit("loadSession", response.data.session_token);
              commit("setSessionInit", 1);
              dispatch("loadUserProfile");
              resolve(response);
            }
            reject({
              msg: "Unknown error. Please try again.",
              error: true
            });
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    reloadSession({ commit, dispatch }, sessionToken) {
      return new Promise(resolve => {
        if (sessionToken) {
          commit("loadSession", sessionToken);
          commit("setSessionInit", 1);
          dispatch("loadUserProfile");
          resolve(sessionToken);
        }
      });
    },
    initSession({ commit, dispatch }, user, persistent = true) {
      commit("setSessionLoadingStatus", loadStates.PENDING);
      return new Promise((resolve, reject) => {
        SESSION_API.initSession(user)
          .then(response => {
            commit("loadSession", response.data.session_token, persistent);
            commit("setSessionInit", 1);
            commit("setSessionLoadingStatus", loadStates.SUCCESS);
            dispatch("loadUserToken", user);
            dispatch("loadUserProfile");
            dispatch("login");
            resolve(response);
          })
          .catch(error => {
            commit("setSessionLoadingStatus", loadStates.ERROR);
            reject(error);
          });
      });
    },
    killSession({ commit, dispatch }) {
      return new Promise(resolve => {
        SESSION_API.killSession().finally(() => {
          commit("removeSession");
          dispatch("logoutUser").then(() => {
            resolve("logout");
          });
        });
      });
    },
    deleteSession({ commit }) {
      commit("removeSession");
    }
  },

  mutations: {
    loadSession(state, token, persistent = true) {
      state.session_token = token;
      axios.defaults.headers.common["Session-Token"] = token;
      if (persistent) localStorage.setItem("session_token", token);
    },
    setSessionInit(state, status) {
      state.sessionInit = status;
    },
    setSessionLoadingStatus(state, status) {
      state.sessionLoadingStatus = status;
    },
    removeSession(state) {
      state.session_token = null;
      localStorage.removeItem("session_token");
      localStorage.removeItem("user_token");
    }
  },
  getters: {
    getSessionToken(state) {
      return state.session_token;
    },
    getSessionLoadState(state) {
      return state.sessionLoadingStatus;
    },
    sessionIsInit(state) {
      return state.sessionInit === 1;
    },
    getUserApiToken(state) {
      return state.user_api_token;
    }
  }
};
