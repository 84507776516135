<template>
  <v-row>
    <v-col cols="6">
      <v-carousel
        cycle
        height="400"
        hide-delimiter-background
        show-arrows-on-hover
        :interval="10000"
        vertical
      >
        <v-carousel-item v-for="(transport, i) in lignes" :key="i">
          <div class="clock-container d-flex justify-center">
            <h3 v-html="formattedTransportText(transport)"></h3>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col cols="3" style="position: relative">
      <v-divider
        class="mx-4"
        style="position: absolute; left: 0; top: 0"
        vertical
      ></v-divider>
      <div class="clock-container d-flex justify-center align-center ml-3">
        <template v-if="isMobile"
          ><h3 class="mr-2 font-weight-bold2">Tra.</h3></template
        >
        <template v-else>
          <h3 class="mr-2 font-weight-bold">Trafic :</h3>
        </template>
        <v-chip
          label
          large
          :color="traficInfo.color"
          text-color="footerTextColor"
          class="font-weight-bold"
        >
          <template
            v-if="isMobile && traficInfo.label && traficInfo.label.length > 6"
            ><h5 class="pa-0">
              {{ traficInfo.label.slice(0, 4) }}&hellip;
            </h5></template
          >
          <template
            v-else-if="
              isMobile && traficInfo.label && traficInfo.label.length <= 6
            "
          >
            <h5 class="pa-0">{{ traficInfo.label }}</h5>
          </template>
          <template v-else> {{ traficInfo.label }}</template>
        </v-chip>
      </div>
      <v-divider
        class="mx-4"
        style="position: absolute; right: 0; top: 0"
        vertical
      ></v-divider>
    </v-col>
    <v-col cols="3">
      <div class="clock-container d-flex justify-center align-center ml-3">
        <template v-if="isMobile"
          ><h3 class="mr-2 font-weight-bold2">Q. air</h3></template
        >
        <template v-else>
          <h3 class="mr-2 font-weight-bold2">Qualité d’air :</h3></template
        >
        <v-chip
          large
          label
          :color="qualiteAir.couleur"
          text-color="footerTextColor"
          class="font-weight-bold"
        >
          <template
            v-if="isMobile && qualiteAir.Qual && qualiteAir.Qual.length > 6"
            ><h5 class="pa-0">
              {{ qualiteAir.Qual.slice(0, 4) }}&hellip;
            </h5></template
          >
          <template
            v-else-if="
              isMobile && qualiteAir.Qual && qualiteAir.Qual.length <= 6
            "
          >
            <h5 class="pa-0">{{ qualiteAir.Qual }}</h5>
          </template>
          <template v-else> {{ qualiteAir.Qual }}</template>
        </v-chip>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ref, toRefs, computed, reactive, watch } from "@vue/composition-api";
import { getTimeFromUnix, join, take } from "../../utils";

const indices = [
  {
    label: "n.c.",
    color: "light"
  },
  {
    label: "Fluide",
    color: "rgb(153, 230, 0)"
  },
  {
    label: "Ralenti",
    color: "orange lighten-2"
  },
  {
    label: "Embouteillé",
    color: "warning"
  },
  {
    label: "Fermé",
    color: "danger"
  }
];
export default {
  name: "Transport",
  props: {
    traficData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      list: {},
      entity: null
    };
  },
  computed: {
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lh
      );
    }
  },
  methods: {
    formattedTransportText(el) {
      if (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lh
      ) {
        if (el.times[0] && el.times[0].slice(0, 5) == "00:00") {
          return `${el.nom}`;
        } else {
          return `${el.nom.slice(0, 25)}...<br/>${join(el.times, " - ")}`;
        }
      } else {
        if (el.times[0] && el.times[0].slice(0, 5) == "00:00") {
          return `${el.nom}`;
        } else {
          return `${el.nom}<br/> Prochains départs : ${join(el.times, " - ")}`;
        }
      }
    }
  },
  setup(props) {
    const state = reactive({
      lignes: []
    });
    const transports = computed(() =>
      props.traficData && props.traficData.transport
        ? props.traficData.transport
        : ref([])
    );
    const stop = computed(() => {
      return transports.value.length > 0
        ? transports.value[2].TC.stop
        : ref([]);
    });

    const qualiteAir = computed(() => {
      return transports.value.length > 0 ? transports.value[1].QA : ref([]);
    });

    const traficInfo = computed(() => {
      return transports.value.length > 0
        ? indices[transports.value[0].IR]
        : ref([]);
    });

    watch(stop, stop => {
      const nblignes = 4 + Math.trunc(document.body.offsetWidth / 1450);
      const stopsKeys = Object.keys(stop);
      const lignes = [];
      stopsKeys.forEach(key => {
        if (stop[key] && stop[key].nom) {
          const timables = stop[key].timetable ? stop[key].timetable : [];
          lignes.push({
            nom: stop[key].nom,
            times: take(timables, nblignes).map(time =>
              getTimeFromUnix(time - 3600)
            )
          });
        }
      });
      state.lignes = lignes;
    });
    return {
      ...toRefs(state),
      transports,
      qualiteAir,
      traficInfo,
      stop
    };
  }
};
</script>

<style scoped></style>
