import moment from "../plugins/moment";
import { i18n } from "../plugins/i18n";

export function parse(value) {
  return JSON.parse(value);
}

const includesAny = (arr, values) => values.some(v => arr.includes(v));

export const devEntity = 368;
export const gestProfile = 40;
export const adminProfiles = [10, 17];
export const adminKitProfile = 49;
let tabs = [];

export const permissions = {
  shouldDisplay(ongletNumber, profileId) {
    localStorage.setItem("myTabs", tabs);
    switch (ongletNumber) {
      //profil
      //10:pi_admin / 17:pi_gest /
      //31: kit_visiteur
      //32: kit_logement
      //35: kit_batiment
      //36: kit_location
      //41: kit+doc
      //46: kit+touristic
      //47: kit+kpi
      case 1: //carto
        if ([10, 17, 32].includes(profileId)) {
          if (!tabs.includes("Carto")) {
            tabs.push("Carto");
          }
          return true;
        }
        return false;
      case 2: //technique
        if ([10, 17, 32, 35, 36].includes(profileId)) {
          if (!tabs.includes("Dégradation")) {
            tabs.push("Dégradation");
          }
          return true;
        }
        return false;
      case 3: //presta
        if ([10, 17, 32, 35].includes(profileId)) {
          if (!tabs.includes("Prestation")) {
            tabs.push("Prestation");
          }
          return true;
        }
        return false;
      case 4: //économie
        if ([10, 17].includes(profileId)) {
          if (!tabs.includes("Economique")) {
            tabs.push("Economique");
          }
          return true;
        }
        return false;
      case 5: //document
        if ([10, 17, 35, 41].includes(profileId)) {
          if (!tabs.includes("Document")) {
            tabs.push("Document");
          }
          return true;
        }
        return false;
      case 6: //touristic
        if ([10, 17, 35, 46].includes(profileId)) {
          if (!tabs.includes("Touristique")) {
            tabs.push("Touristique");
          }
          return true;
        }
        return false;
      case 7: //calendrier
        if ([10, 17, 35, 36].includes(profileId)) {
          if (!tabs.includes("Calendrier")) {
            tabs.push("Calendrier");
          }
          return true;
        }
        return false;
      case 8: //accès
        if ([10, 17].includes(profileId)) {
          if (!tabs.includes("Acces")) {
            tabs.push("Acces");
          }
          return true;
        }
        return false;
      case 9: //info
        if ([10, 17, 43].includes(profileId)) {
          if (!tabs.includes("Info")) {
            tabs.push("Info");
          }
          return true;
        }
        return false;
      case 10: //resa
        if ([10, 17, 32].includes(profileId)) {
          if (!tabs.includes("Résa")) {
            tabs.push("Résa");
          }
          return true;
        }
        return false;
      case 11: //data
        //if ([10, 17, 44].includes(profileId)) { // le temps de valider checkup
        if ([10, 17].includes(profileId)) {
          if (!tabs.includes("Data")) {
            tabs.push("Data");
          }
          return true;
        }
        return false;
      case 12: //consigne
        if ([10, 17, 32].includes(profileId)) {
          if (!tabs.includes("Consignes")) {
            tabs.push("Consignes");
          }
          return true;
        }
        return false;
      case 13: //clefs
        if ([10, 17, 48].includes(profileId)) {
          if (!tabs.includes("Clef")) {
            tabs.push("Clef");
          }
          return true;
        }
        return false;
      case 14: //CheckUp
        if ([10, 17, 44].includes(profileId)) {
          if (!tabs.includes("CheckUp")) {
            tabs.push("CheckUp");
          }
          return true;
        }
        return false;
      default:
        return false;
    }
  },

  profilePermission(columnName, profiles) {
    switch (columnName) {
      case "generique": // colonnes générique, travaux, incidents et évènements j+30
        return includesAny([10, 17], profiles);
      case "engagement": //colonnes engagement du bail
        return includesAny([10, 17], profiles);
      case "financier": // colonnes loyers, charges, TF
        return includesAny([10, 17], profiles);
      default:
        return false;
    }
  },
  profileDocPermission(docComment, profiles) {
    switch (docComment) {
      case "publique": // documents XXX accessibles à tous les profils (hors mod)
        return includesAny([10, 17, 31, 32, 33, 34, 35, 36], profiles);
      case "occupant": // documents -XX (doc restreints)-> pour les occupants
        return includesAny([10, 17, 32, 33, 34, 35, 36], profiles);
      case "investisseur": // documents --X (doc les + restreints) doc investisseurs
        return includesAny([10, 17], profiles);
      default:
        return false;
    }
  }
};

const AddCommentAction = 1;

export const ticketActionPermissions = {
  checkPermission(action, profileId) {
    switch (action) {
      case AddCommentAction:
        return [10, 17, 31, 32, 33, 34, 35, 36, 45].includes(profileId);
      default:
        return [10, 17, 40].includes(profileId);
    }
  }
};

export const statusList = local => [
  {
    name: i18n.t("tableStatus.new", local),
    status: 1,
    color: "#00AFD7"
  },
  {
    name: i18n.t("tableStatus.attributed", local),
    status: 2,
    color: "#0072CE"
  },
  {
    name: i18n.t("tableStatus.planned", local),
    status: 3,
    color: "#00965E"
  },
  {
    name: i18n.t("tableStatus.in_progress"),
    local,
    status: 4,
    color: "#FF8200"
  },
  {
    name: i18n.t("tableStatus.resolved", local),
    status: 5,
    color: "#43B02A"
  },
  {
    name: i18n.t("tableStatus.closed", local),
    status: 6,
    color: "#333F48"
  }
];

export const statusList2 = local => [
  {
    name: i18n.t("tableStatus.new", local),
    status: 1,
    color: "#00AFD7"
  },
  {
    name: i18n.t("tableStatus.attributed", local),
    status: 2,
    color: "#0072CE"
  },
  {
    name: i18n.t("tableStatus.planned", local),
    status: 3,
    color: "#00965E"
  },
  {
    name: i18n.t("tableStatus.in_progress"),
    local,
    status: 4,
    color: "#FF8200"
  },
  {
    name: i18n.t("tableStatus.resolved", local),
    status: 5,
    color: "#43B02A"
  }
];

export const statusList3 = local => [
  {
    name: i18n.t("header.prestation", local),
    status: 2,
    color: "#00AFD7"
  }
];

export const statusListAccess = local => [
  {
    name: i18n.t("tableStatus.new", local),
    status: 1,
    color: "#00AFD7"
  },
  {
    name: i18n.t("tableStatus.closed", local),
    status: 6,
    color: "#333F48"
  }
];

export const statusListEquipment = [
  {
    name: "NOK",
    status: 10,
    color: "#FF8200"
  },
  {
    name: "OK",
    status: 11,
    color: "#00965E"
  }
];

export const statusListKeys = [
  {
    name: "AGENCE",
    state: 1
  },
  {
    name: "PRESTATAIRE",
    state: 2
  },
  {
    name: "LOCATAIRE",
    state: 3
  },
  {
    name: "A REFAIRE",
    state: 4
  },
  {
    name: "PERDUE",
    state: 5
  }
];

export const loadStates = {
  INITIAL_STATE: 0,
  PENDING: 1,
  SUCCESS: 2,
  ERROR: 3
};

let decoder;

export function decode(html) {
  decoder = decoder || document.createElement("div");
  decoder.innerHTML = html;
  return decoder.textContent;
}

export function getColor(status = 1) {
  switch (status) {
    case 4:
      return "red accent-2";
    case 2:
      return "success";
    default:
      return "indigo";
  }
}

export function getAvatarName(user_name) {
  try {
    return user_name
      ? user_name
          .toUpperCase()
          .split(" ")[0]
          .substring(0, 1)
          .concat(
            user_name
              .toUpperCase()
              .split(" ")[1]
              .substring(0, 2)
          )
      : "x";
  } catch (ex) {
    return "x";
  }
}

const tagsToReplace = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;"
};

const tagsToSup = {
  "<p>": " ",
  "</p>": " "
};

export function escapeHTML(str) {
  return str.replace(/[&<>]/g, tag => tagsToReplace[tag] || tag);
}

export function supHTML(str) {
  let str2 = str.replace(/<\/p>/g, tag => tagsToSup[tag] || tag);
  return str2.replace(/<p>/g, tag => tagsToSup[tag] || tag);
}

export const take = (arr, n = 1) => arr.slice(0, n);

export function getTime(secs) {
  const measuredTime = new Date(null);
  measuredTime.setSeconds(secs); // specify value of SECONDS
  return measuredTime.toISOString().substr(11, 8);
}
export function numStr(a, b) {
  a = "" + a;
  b = b || " ";
  var c = "",
    d = 0;
  while (a.match(/^0[0-9]/)) {
    a = a.substr(1);
  }
  for (var i = a.length - 1; i >= 0; i--) {
    c = d != 0 && d % 3 == 0 ? a[i] + b + c : a[i] + c;
    d++;
  }
  return c;
}
export function getTimeFromUnix(secs) {
  return moment.unix(secs).format("HH:mm");
}

export const join = (arr, separator = ",", end = separator) =>
  arr.reduce(
    (acc, val, i) =>
      i === arr.length - 2
        ? acc + val + end
        : i === arr.length - 1
        ? acc + val
        : acc + val + separator,
    ""
  );

export const toTitleCase = str =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");

export const convertToTitleCase = str =>
  str
    .split(" ")
    .map(x => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");

export function CreateUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const capitalize = ([first, ...rest], lowerRest = false) =>
  first.toUpperCase() +
  (lowerRest ? rest.join("").toLowerCase() : rest.join(""));

export const last = arr =>
  arr && arr.length ? arr[arr.length - 1] : undefined;

const REDIRECT_TIMEOUT = 60000;
const NAVBAR_WIDTH = 200;
const QRCODE_WIDTH = 100;
const TRANSPORT_DATE_TIMEOUTE = 60000;

const SOLUTION_TYPE = 1;
const IMAGE_TYPE = 3;
const TASK_TYPE = 2;

export {
  REDIRECT_TIMEOUT,
  NAVBAR_WIDTH,
  QRCODE_WIDTH,
  TRANSPORT_DATE_TIMEOUTE,
  SOLUTION_TYPE,
  IMAGE_TYPE,
  TASK_TYPE
};
export const union = (a, b) => Array.from(new Set([...a, ...b]));

export function getStatus(status, local = "fr") {
  return statusList(local).find(el => el.status === status).name;
}

export const remove = (arr, func) =>
  Array.isArray(arr)
    ? arr.filter(func).reduce((acc, val) => {
        arr.splice(arr.indexOf(val), 1);
        return acc.concat(val);
      }, [])
    : [];

export function getNestedValue(obj, path, fallback = null) {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function getObjectValueByPath(obj, path, fallback = null) {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== "string") return fallback;
  if (obj[path] !== undefined) return obj[path];
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  return getNestedValue(obj, path.split("."), fallback);
}

export function formatID(id) {
  id = "" + id;
  if (id.length < 6) return id;
  return `${id.substring(3, 4)}${id.substring(4, 6)}.${id.substring(
    6,
    id.length
  )}`;
}
export function hideElt(id) {
  id = "";
  return id;
}
export function formatCP(id) {
  id = "" + id;
  return `${id.substring(0, 2)}`;
}
export function formatGps(id) {
  id = "" + id;
  return `${id.split("/")[0].substring(0, 5)}° / ${id
    .split("/")[1]
    .substring(0, 4)}°`;
}
export function formatInputGps(id) {
  id = "" + id;
  return `${id.replace(/ /g, "").replace(/,/g, ".")}`;
}

export function formatTel(id) {
  id = "" + id;
  return `${id.substring(0, id.length - 8)} 
  ${id.substring(id.length - 6, id.length - 8)}
  ${id.substring(id.length - 4, id.length - 6)}
  ${id.substring(id.length - 2, id.length - 4)}
  ${id.substring(id.length, id.length - 2)}`;
}
export function formatValeur(id) {
  id = "" + id;
  if (id.includes(".")) {
    return `${numStr(id.split(".")[0])},${id.split(".")[1]}`;
  } else {
    return `${numStr(id)}`;
  }
}
export function shouldDisplay(reservation) {
  const currentDate = moment().format("YYYY-MM");

  return (
    reservation.begin !== "0000-00-00 00:00:00" &&
    reservation.end !== "0000-00-00 00:00:00" &&
    moment(reservation.begin).isAfter(currentDate, "date")
  );
}

export const get = (from, ...selectors) =>
  [...selectors].map(s =>
    s
      .replace(/\[([^[\]]*)\]/g, ".$1.")
      .split(".")
      .filter(t => t !== "")
      .reduce((prev, cur) => prev && prev[cur], from)
  );

export const mobileHeaders = local => [
  {
    text: "Périmètre",
    align: "center",
    sortable: true,
    value: "name",
    class: "t-row"
  },
  {
    text: i18n.t("header.opening_date", local),
    align: "center",
    sortable: true,
    value: "date",
    class: "t-row"
  },
  {
    text: i18n.t("header.resolution_date", local),
    align: "center",
    sortable: true,
    value: "solvedate",
    class: "t-row"
  },
  {
    text: i18n.t("header.status", local),
    align: "center",
    sortable: true,
    value: "statusName",
    class: "t-row"
  },
  {
    text: i18n.t("header.description", local),
    align: "left",
    sortable: false,
    value: "content",
    class: "t-row"
  },
  {
    text: i18n.t("header.category", local),
    align: "center",
    sortable: true,
    value: "itilcategories_id",
    class: "t-row"
  },
  {
    text: i18n.t("header.contact", local),
    align: "center",
    sortable: false,
    value: "emailfield",
    class: "t-row"
  },
  {
    text: i18n.t("header.last_comment", local),
    align: "left",
    sortable: false,
    value: "last_comment",
    class: "t-row"
  }
];

export const accessmobileHeaders = [
  {
    text: "Date entrée",
    align: "center",
    sortable: true,
    value: "date",
    class: "t-row"
  },
  {
    text: "Date sortie",
    align: "center",
    sortable: true,
    value: "solvedate",
    class: "t-row"
  },
  {
    text: "Statut",
    align: "center",
    sortable: true,
    value: "statusName",
    class: "t-row"
  },
  {
    text: "Description",
    align: "left",
    sortable: false,
    value: "content",
    class: "t-row"
  },
  {
    text: "Catégorie",
    align: "center",
    sortable: true,
    value: "itilcategories_id",
    class: "t-row"
  },
  {
    text: "Dernier commentaire",
    align: "left",
    sortable: false,
    value: "last_comment",
    class: "t-row"
  }
];

export const equipmentmobileHeaders = [
  {
    text: "Date entrée",
    align: "center",
    sortable: true,
    value: "date",
    class: "t-row"
  },
  {
    text: "Date sortie",
    align: "center",
    sortable: true,
    value: "solvedate",
    class: "t-row"
  },
  {
    text: "Statut",
    align: "center",
    sortable: true,
    value: "statusName",
    class: "t-row"
  },
  {
    text: "Description",
    align: "left",
    sortable: false,
    value: "content",
    class: "t-row"
  },
  {
    text: "Catégorie",
    align: "center",
    sortable: true,
    value: "itilcategories_id",
    class: "t-row"
  },
  {
    text: "Dernier commentaire",
    align: "left",
    sortable: false,
    value: "last_comment",
    class: "t-row"
  }
];

export const headers = local => [
  {
    text: "Logement",
    value: "equipment",
    sortable: true,
    class: "t-row"
  },
  {
    text: "id",
    align: "center",
    sortable: false,
    value: "id",
    class: "t-row"
  },
  {
    text: i18n.t("header.perimeter", local),
    align: "center",
    sortable: false,
    value: "name",
    class: "t-row"
  },
  {
    text: i18n.t("header.opening_date", local),
    align: "center",
    sortable: true,
    value: "date",
    class: "t-row"
  },
  {
    text: i18n.t("header.status", local),
    align: "center",
    sortable: true,
    value: "statusName",
    class: "t-row"
  },
  {
    text: i18n.t("header.description", local),
    align: "left",
    sortable: false,
    value: "content",
    class: "t-row"
  },
  {
    text: i18n.t("header.category", local),
    align: "center",
    sortable: true,
    value: "itilcategories_id",
    class: "t-row"
  },
  {
    text: "Public / Privé",
    align: "center",
    sortable: false,
    value: "type",
    class: "t-row"
  },
  {
    text: i18n.t("header.contact"),
    align: "center",
    sortable: false,
    value: "emailfield",
    class: "t-row"
  },
  {
    text: i18n.t("header.last_comment", local),
    align: "left",
    sortable: false,
    value: "last_comment",
    class: "t-row"
  },
  {
    text: i18n.t("header.resolution_date", local),
    align: "center",
    sortable: true,
    value: "solvedate",
    class: "t-row"
  },
  {
    text: "Attrib.",
    align: "center",
    sortable: false,
    value: "attrib_user",
    class: "t-row"
  }
];

export const accessheaders = local => [
  {
    text: i18n.t("header.user", local),
    align: "left",
    sortable: false,
    value: "content",
    class: "t-row"
  },
  {
    text: i18n.t("subHeader.entry_date", local),
    align: "center",
    sortable: true,
    value: "date",
    class: "t-row"
  },
  {
    text: i18n.t("subHeader.release_date", local),
    align: "center",
    sortable: true,
    value: "solvedate",
    class: "t-row"
  },
  {
    text: i18n.t("header.status", local),
    align: "center",
    sortable: true,
    value: "statusName",
    class: "t-row"
  },
  {
    text: i18n.t("header.last_comment", local),
    align: "left",
    sortable: false,
    value: "last_comment",
    class: "t-row"
  }
];

export const equipmentheaders = local => [
  {
    text: "id",
    align: "center",
    sortable: false,
    value: "id",
    class: "t-row"
  },
  {
    text: i18n.t("header.description", local),
    align: "left",
    sortable: false,
    value: "content",
    class: "t-row"
  },
  {
    text: i18n.t("header.opening_date", local),
    align: "center",
    sortable: true,
    value: "date",
    class: "t-row"
  },
  {
    text: i18n.t("header.date_grant", local),
    align: "center",
    sortable: true,
    value: "attributdate",
    class: "t-row"
  },
  {
    text: i18n.t("header.resolution_date", local),
    align: "center",
    sortable: true,
    value: "solvedate",
    class: "t-row"
  },
  {
    text: i18n.t("header.status", local),
    align: "center",
    sortable: true,
    value: "statusName",
    class: "t-row"
  },
  {
    text: i18n.t("header.category", local),
    align: "center",
    sortable: true,
    value: "itilcategories_id",
    class: "t-row"
  },
  {
    text: i18n.t("header.contact", local),
    align: "center",
    sortable: false,
    value: "emailfield",
    class: "t-row"
  },
  {
    text: i18n.t("header.last_comment", local),
    align: "left",
    sortable: false,
    value: "last_comment",
    class: "t-row"
  }
];

export const staticHeader = local => ({
  name: "",
  surfacemtwofield: i18n.t("subHeader.area", local),
  nomcommercialfield: i18n.t("subHeader.tenant", local),
  etageportefield: i18n.t("subHeader.location", local),
  datedbutdebailfield: i18n.t("subHeader.entry_date", local),
  datefindebailfield: i18n.t("subHeader.release_date", local),
  soldefield: i18n.t("subHeader.balance", local),
  loyerannuelhtfield: i18n.t("subHeader.rents", local),
  charge: i18n.t("subHeader.loads", local),
  impot: i18n.t("subHeader.property_taxes", local),
  travaux1: i18n.t("subHeader.year_minus_1", local),
  investissement: i18n.t("subHeader.year", local),
  travaux2: i18n.t("subHeader.year_plus_1", local),
  tickets: i18n.t("subHeader.processing_tickets", local),
  technique: i18n.t("subHeader.resolve_tickets", local),
  events: i18n.t("subHeader.event", local)
});

export function srcToFile(src, fileName, mimeType) {
  return fetch(src)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}

export const omit = (obj, arr) =>
  Object.keys(obj)
    .filter(k => !arr.includes(k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});

export const merge = (...objs) =>
  [...objs].reduce(
    (acc, obj) =>
      Object.keys(obj).reduce((a, k) => {
        acc[k] = acc.hasOwnProperty(k)
          ? [].concat(acc[k]).concat(obj[k])
          : obj[k];
        return acc;
      }, {}),
    {}
  );

export const sumBy = (arr, fn) =>
  arr
    .map(typeof fn === "function" ? fn : val => (val[fn] > 0 ? val[fn] : 0))
    .reduce((acc, val) => +acc + +val, 0);

export const reject = (pred, array) =>
  array.filter((...args) => !pred(...args));

export const uniqueElementBy = (arr, fn) =>
  arr.reduce((acc, v) => {
    if (!acc.some(x => fn(v, x))) acc.push(v);
    return acc;
  }, []);

export const ISO_FORMAT = "yyyy-MM-dd";
export const DEFAULT_DATE = moment().format("YYYY-MM-DD");
export const DEFAULT_ACTION_LABELS = local => ({
  apply: i18n.t("labels.apply", local),
  cancel: i18n.t("labels.cancel", local),
  reset: "Reset"
});

export const groupBy = (arr, fn) =>
  arr
    .map(typeof fn === "function" ? fn : val => val[fn])
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i]);
      return acc;
    }, {});

export const flatten = (arr, depth = 1) =>
  arr.reduce(
    (a, v) =>
      a.concat(depth > 1 && Array.isArray(v) ? flatten(v, depth - 1) : v),
    []
  );

export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const base64ToString =
  process.env.VUE_ENV === "server"
    ? function(base64) {
        return Buffer.from(base64, "base64").toString();
      }
    : window.atob;

export const toComponentName = fileName =>
  fileName
    // Remove the "./_" from the beginning
    .replace(/^\.\/_/, "")
    // Remove the file extension from the end
    .replace(/\.\w+$/, "")
    // Split up kebabs
    .split("-")
    // Upper case
    .map(kebab => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    // Concatenated
    .join("");

export const orderBy = (arr, props, orders) =>
  [...arr].sort((a, b) =>
    props.reduce((acc, prop, i) => {
      if (acc === 0) {
        const [p1, p2] =
          orders && orders[i] === "desc"
            ? [b[prop], a[prop]]
            : [a[prop], b[prop]];
        acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0;
      }
      return acc;
    }, 0)
  );

export const number_format = (number, decimals, dec_point, thousands_sep) => {
  // Strip all characters but numerical ones.
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function(n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};
