<template>
  <v-col cols="12">
    <v-card class="mt-6" flat min-width="300px">
      <v-card v-if="isCartoMap && isGps" width="100%">
        <map-view :list="locationList"></map-view>
      </v-card>
      <v-card-title v-if="isMobile" class="headline display-1">
        <v-col cols="6">
          Logements
        </v-col>
        <v-col cols="6" class="mt-3 pr-2">
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            single-line
            hide-details
            class="mt-n2"
          >
          </v-text-field>
        </v-col>
      </v-card-title>
      <v-card-title v-else class="headline display-1">
        <v-col cols="4">
          {{ $t("tableTitle.accommodation_list") }}
        </v-col>
        <v-col cols="4" class="mt-3 pr-2">
          <v-select
            v-if="!isVisit"
            label="Filtrer par statut"
            :items="states_id"
            clearable
            v-model="selectedStatus"
            multiple
            chips
          ></v-select>
        </v-col>
        <v-col cols="3" offset="1" class="mt-3 pr-2">
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            clearable
            single-line
            hide-details
            class="mt-n2"
          >
          </v-text-field>
        </v-col>
      </v-card-title>
      <v-card-subtitle v-if="!this.searched">
        {{
          filteredItems.length > 1
            ? filteredItems.length + " logements (filtrés) "
            : filteredItems.length + " logement (filtré) "
        }}
        /
        {{
          selectedLgt.length > 1
            ? selectedLgt.length + " logements sélectionnés"
            : selectedLgt.length + " logement sélectionné"
        }}
      </v-card-subtitle>
      <v-card-subtitle v-else>
        {{
          selectedLgt.length > 1
            ? selectedLgt.length + " logements sélectionnés"
            : selectedLgt.length + " logement sélectionné"
        }}
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          v-model="selectedLgt"
          :headers="filteredHeader"
          :items="filteredItems"
          item-key="id"
          class="table is_bordered"
          :loading="loading"
          :search="searched"
          :page.sync="page"
          :sort-by="sorted"
          :sort-desc="sortedDesc"
          :items-per-page="itemsPerPage"
          hide-default-footer
          show-select
          @page-count="pageCount = $event"
        >
          <template v-slot:header.data-table-select="{ props, on }">
            <v-menu offset-y v-if="!isVisit">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-avatar class="white ml-1" size="32">
                    <v-icon color="primary">menu</v-icon>
                  </v-avatar>
                </v-btn>
              </template>
              <v-list class="pa-0" light>
                <v-list-item
                  style="background-color: rgb(240, 240, 240);"
                  @click="selectAll()"
                >
                  Sélectionner tout
                </v-list-item>
                <v-list-item
                  @click="unselectAll()"
                  :disabled="selectedLgt.length === 0"
                >
                  Effacer sélection
                </v-list-item>
                <v-list-item
                  style="background-color: rgb(240, 240, 240);"
                  @click="statusLgt(selectedLgt, 'statut')"
                  :disabled="selectedLgt.length === 0"
                >
                  Passer à R2R
                </v-list-item>
                <v-list-item
                  @click="sendInfo(selectedLgt)"
                  :disabled="selectedLgt.length === 0"
                >
                  Prévenir le locataire suivant
                </v-list-item>
                <v-list-item
                  style="background-color: rgb(240, 240, 240);"
                  @click="changeLgt(selectedLgt)"
                  :disabled="selectedLgt.length != 1"
                >
                  Modifier le logement
                </v-list-item>
                <v-list-item
                  @click="commentLgt(selectedLgt)"
                  :disabled="selectedLgt.length === 0"
                >
                  Modifier plusieurs commentaires
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:item="{ item }">
            <tr :item="item" :headers="filteredHeader" rtl>
              <td>
                <v-checkbox
                  :key="item.id"
                  v-model="selectedLgt"
                  :value="item"
                  class="mb-n5 mt-n1"
                ></v-checkbox>
              </td>
              <td
                @click="clicked(item)"
                v-if="!isVisit"
                v-html="formatText(item)"
              ></td>
              <td @click="popup()" align="center">
                <v-chip
                  :color="
                    item.states_id === 'R2R'
                      ? 'green'
                      : item.states_id === 'IN'
                      ? 'primary'
                      : item.states_id === 'OUT'
                      ? 'orange'
                      : item.states_id == '1'
                      ? 'grey'
                      : item.states_id == 'CLOSE'
                      ? 'black'
                      : 'light'
                  "
                  text-color="white"
                >
                  {{ item.states_id }}</v-chip
                >
              </td>
              <td @click="clicked(item)" v-if="isVisit">
                {{ item.name }}
              </td>
              <td @click="clicked(item)">
                {{ formatDate(item.date_mod) }}
              </td>
              <td @click="clicked(item)">
                {{ item.comment }}
              </td>
              <td @click="clicked(item)" align="center" v-if="!isVisit">
                {{ item.tickets }}
              </td>
              <td @click="clicked(item)" align="center" v-if="!isVisit">
                {{ item.technique }}
              </td>
              <td @click="clicked(item)" align="center" v-if="!isVisit">
                {{ item.permanent }}
              </td>
              <td @click="clicked(item)" v-if="isGps">
                {{
                  item.gps
                    ? item.gps != "0/0"
                      ? formatGps(item.gps)
                      : "-"
                    : "-"
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"> </v-pagination>
        </div>
      </v-card-text>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-col>
</template>
<script>
import { UserSessionData } from "../../mixins/userData";
import MapView from "../../components/Map";
import moment from "../../plugins/moment";
import USER_API from "../../api/user";
import OBJECT_API from "../../api/object";
import { mapGetters } from "vuex";
import { PORTAL_CONFIG } from "../../config";
import { formatGps, formatInputGps } from "../../utils";
import Swal from "sweetalert2";
import { mySwalClass } from "../../utils/alert";

export default {
  name: "PluginGenericobjectLogement",
  components: { MapView },
  data() {
    return {
      page: 1,
      selectedLgt: [],
      itemsPerPage: 500,
      pageCount: 0,
      search: "",
      isCartoMap: true,
      locationList: [],
      states: ["R2R"],
      states_id: ["R2R", "OUT", "IN", "CLOSE", 0, 1],
      selectedStatus: [],
      loading: false,
      snack: false,
      snackColor: "",
      snackText: ""
    };
  },
  mixins: [UserSessionData],
  mounted() {
    this.loadData();
    let str = "";
    if (this.$route.params.state) str = this.$route.params.state;
    if (this.$route.query.status) str = this.$route.query.status;
    if (str != "") {
      //const routeStatus = str.charAt(0).toUpperCase() + str.slice(1);
      if (str == "NA") this.selectedStatus = [0];
      else if (str == "R2R") this.selectedStatus = [1, "R2R"];
      else {
        this.selectedStatus = [];
        this.selectedStatus.push(str);
      }
    }
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken",
      "getAllTickets",
      "getSessionToken"
    ]),
    items() {
      return this.list.map(entity => {
        const ticketdata = this.getAllTicketsData(entity);
        entity.tickets = ticketdata[0] > 0 ? ticketdata[0] : "-";
        entity.technique = ticketdata[1] > 0 ? ticketdata[1] : "-";
        entity.permanent = ticketdata[2] > 0 ? ticketdata[2] : "-";
        return entity;
      });
    },
    filteredItems() {
      this.unselectAll();
      return this.list.filter(i => {
        const ticketdata = this.getAllTicketsData(i);
        i.tickets = ticketdata[0] > 0 ? ticketdata[0] : "-";
        i.technique = ticketdata[1] > 0 ? ticketdata[1] : "-";
        i.permanent = ticketdata[2] > 0 ? ticketdata[2] : "-";
        return (
          this.selectedStatus.length === 0 ||
          this.selectedStatus.includes(i.states_id)
        );
      });
    },
    sorted() {
      return "date_mod";
    },
    sortedDesc() {
      return true;
    },
    searched() {
      this.unselectAll();
      if (this.search && this.search.includes(" ")) {
        let nbMots = 0;
        const space = / /g;
        let searches = [];
        while (space.exec(this.search)) {
          nbMots++;
        }
        for (let i = 0; i < nbMots; i++) {
          searches[i] = this.search.split(" ")[i];
        }
        if (this.search.split(" ")[nbMots]) {
          nbMots++;
          searches[nbMots - 1] = this.search.split(" ")[nbMots - 1];
        }
        if (nbMots == 2) {
          return searches[0] && searches[1];
        }
        if (nbMots == 3) {
          return searches[0] && searches[1] && searches[2];
        }
      }
      return this.search;
    },
    filteredHeader() {
      let headers = [];
      if (!this.isVisit) {
        headers.push(
          {
            text: "LOGEMENT",
            align: "left",
            sortable: true,
            value: "name"
          },
          {
            text: "ETAT",
            align: "center",
            sortable: true,
            value: "states_id"
          },
          {
            text: "DATE MAJ",
            align: "left",
            sortable: true,
            value: "date_mod"
          },
          {
            text: "COMMENTAIRE",
            align: "left",
            sortable: false,
            value: "comment"
          }
        );
      } else {
        headers.push(
          {
            text: "ETAT",
            align: "center",
            sortable: true,
            value: "states_id"
          },
          {
            text: "LOGEMENT",
            align: "left",
            sortable: true,
            value: "name"
          },
          {
            text: "COMMENTAIRE",
            align: "left",
            sortable: false,
            value: "comment"
          }
        );
      }
      if (!this.isVisit) {
        headers.push(
          {
            text: "DEGRADATION EN COURS",
            align: "center",
            sortable: true,
            value: "tickets",
            class: "primary white--text",
            isNumber: true
          },
          {
            text: "RESOLUES",
            align: "center",
            sortable: true,
            value: "technique",
            class: "primary white--text",
            isNumber: true
          },
          {
            text: "PERMANENTES",
            align: "center",
            sortable: false,
            value: "permanent",
            class: "primary white--text",
            isNumber: true
          }
        );
      }
      if (this.isGps) {
        headers.push({
          text: "GPS",
          align: "left",
          sortable: false,
          value: "gps"
        });
      }

      return headers;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    isVisit() {
      return this.getUserProfile.id == 31;
    },
    isGps() {
      return this.getActiveEntity.altitude != 0;
    }
  },
  methods: {
    formatGps,
    formatText(lgt) {
      if (lgt.date_mod > localStorage.getItem("last2Refresh")) {
        return "<b>> " + lgt.name + "</b>";
      } else return lgt.name;
    },
    formatDate(date) {
      let day = "";
      if (!date) return null;
      if (date.slice(0, 10) == moment().format("YYYY-MM-DD")) {
        day = "auj.";
      } else if (
        date.slice(0, 10) ==
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ) {
        day = "hier";
      } else day = moment(date).format("DD-MM");
      return day + " " + moment(date).format("HH:mm");
    },
    formatInputGps,
    clicked: function(item) {
      let alignement = this.$vuetify.breakpoint.width - 500;
      const windowFeatures = "left=" + alignement + ",width=500,height=800";
      if (!this.isVisit) {
        window.open(
          PORTAL_CONFIG.SERVER_WEB +
            "/" +
            PORTAL_CONFIG.APP_NAME +
            "/" +
            PORTAL_CONFIG.CLIENT_NAME +
            "/loader.php?script=ticket_it&user=" +
            this.getUserToken +
            "&session=" +
            this.getSessionToken +
            "&lot=info_lgt&gest&direct" +
            "&itemtype=PluginGenericobjectLogement" +
            "&itemtypeID=" +
            item.id +
            "&Entity=" +
            this.$route.params.id,
          "_blank",
          windowFeatures
          /*PORTAL_CONFIG.SERVER_WEB +
            "/" +
            PORTAL_CONFIG.APP_NAME +
            "/" +
            PORTAL_CONFIG.CLIENT_NAME +
            "/itemtype.php?user=" +
            this.getUserToken +
            "&ept=" +
            item.code_pi +
            "&entity=" +
            this.$route.params.id*/
        );
      }
    },
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        USER_API.getEquipment("Logement")
          .then(response => {
            this.list = response.data.filter(el => el.operationnel == 1);
            const size = this.list.length;
            this.locationList = [];
            let nbgps = 0;
            //let clientName = this.getActiveEntity.completename
            //  .split(">")[1]
            //  .trim();
            if (size > 0) {
              //pour mettre les points sur la carte
              for (let i = 0; i < size; i++) {
                if (
                  this.list[i].gps &&
                  this.list[i].gps !== "/" &&
                  this.list[i].gps !== "0/0"
                ) {
                  nbgps = nbgps + 1;
                  if (this.list[i].states_id > 0) {
                    this.locationList.push({
                      name:
                        isNaN(this.list[i].name) === true
                          ? this.list[i].name.toUpperCase()
                          : this.list[i].name,
                      states_id: this.list[i].states_id,
                      /* si souci avec les markers, il faudrait peut etre utiliser cela
                      operationnel:
                        this.list[i].operationnel !== undefined
                          ? this.list[i].operationnel
                          : "NA",*/
                      lat: this.list[i].gps.split("/")[0],
                      lng: this.list[i].gps.split("/")[1],
                      link:
                        PORTAL_CONFIG.SERVER_WEB +
                        "/" +
                        PORTAL_CONFIG.APP_NAME +
                        "/" +
                        PORTAL_CONFIG.CLIENT_NAME +
                        "/loader.php?script=ticket_it&user=" +
                        this.getUserToken +
                        "&session=" +
                        this.getSessionToken +
                        "&lot=info_lgt&gest&direct" +
                        "&itemtype=PluginGenericobjectLogement" +
                        "&itemtypeID=" +
                        this.list[i].id +
                        "&Entity=" +
                        this.$route.params.id
                      // { name: "lots.show", params: { id: this.list[i].id } }
                      /*PORTAL_CONFIG.SERVER_WEB +
                        "/" +
                        PORTAL_CONFIG.APP_NAME +
                        "/" +
                        PORTAL_CONFIG.CLIENT_NAME +
                        "/itemtype.php?user=" +
                        this.getUserToken +
                        "&ept=" +
                        this.list[i].code_pi +
                        "&entity=" +
                        this.$route.params.id*/
                    });
                  }
                }
              }
              //pour écrire le tableau
              for (let i = 0; i < size; i++) {
                //statut_is : 2->R2R 3-> IN 4->OUT
                if (this.list[i].states_id === 2) {
                  this.list[i].states_id = "R2R";
                } else if (this.list[i].states_id === 3) {
                  this.list[i].states_id = "IN";
                } else if (this.list[i].states_id === 4) {
                  this.list[i].states_id = "OUT";
                } else if (this.list[i].states_id === 8) {
                  this.list[i].states_id = "CLOSE";
                }
                if (isNaN(this.list[i].name) === true) {
                  this.list[i].name = this.list[i].name.toUpperCase();
                }
                this.list[i].dimension = this.list[i].dimension;
                /*if (this.list[i].expirationdate != null) {
                  this.list[i].duree =
                    this.list[i].expirationdate.split("-")[0] -
                    moment().format("YYYY") +
                    " an(s)";
                }*/
              }
            }
            if (this.getActiveEntity.altitude == 0) {
              nbgps = 0;
            }
            if (nbgps === 0) {
              this.isCartoMap = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getAllTicketsData(entity) {
      const ticketsAll = this.getAllTickets.filter(ticket => {
        try {
          const name = JSON.parse(ticket.name);
          return (
            name.itemtypeID == entity.id &&
            (ticket.itilcategories_id === 1 || ticket.itilcategories_id === 4)
          );
        } catch {
          return false;
        }
      });
      const status1 = ticketsAll.filter(
        st1 => st1.status === 5 || st1.status === 6
      );
      const status2 = ticketsAll.filter(
        st2 =>
          st2.status !== 5 && st2.status !== 6 && st2.itilcategories_id === 1
      );
      const status3 = ticketsAll.filter(st3 => st3.itilcategories_id === 4);
      return [
        status2.length > 0 ? status2.length : "-",
        status1.length > 0 ? status1.length : "-",
        status3.length > 0 ? status3.length : "-"
      ];
    },
    unselectAll() {
      this.selectedLgt = [];
    },
    selectAll() {
      if (this.search != "") {
        Swal.fire({
          title: "La sélection multiple ne fonctionne pas avec la recherche.",
          showConfirmButton: false,
          icon: "warning",
          timer: 2500,
          timerProgressBar: true,
          customClass: mySwalClass
        });
      } else {
        let lgts = [];
        for (lgts in this.filteredItems) {
          this.selectedLgt.push(this.filteredItems[lgts]);
        }
      }
    },
    async changeLgt(selectedLgt) {
      let lgtId = selectedLgt[0].id;
      //let lgtContent = this.list.find(el => (el.id = lgtId));
      let lgtContent = selectedLgt[0];
      let formerName = lgtContent.name;
      let formerComment = lgtContent.comment ? lgtContent.comment : "";
      let formerLat = lgtContent.gps.split("/")[0];
      let formerLng = lgtContent.gps.split("/")[1];
      const { value: formValues } = await Swal.fire({
        title: "Modifier le logement",
        html: `
          <input id="swal-input1" class="swal2-input" value="${formerName}">
          <input id="swal-input2" class="swal2-input" value="${formerComment}" placeholder="commentaire">
          <input id="swal-input3" class="swal2-input" value="${formerLat}" placeholder="latitude">
          <input id="swal-input4" class="swal2-input" value="${formerLng}" placeholder="longitude">
        `,
        focusConfirm: false,
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        customClass: mySwalClass,
        preConfirm: () => {
          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
            document.getElementById("swal-input3").value,
            document.getElementById("swal-input4").value
          ];
        }
      });
      if (formValues) {
        if (formValues[0] != formerName) {
          try {
            selectedLgt[0].name = formValues[0];
            USER_API.changeName("Logement", lgtId, formValues[0]);
          } catch ({ response }) {
            this.errors.push({
              title: "Erreur ",
              msg: response.data,
              snackbar: true
            });
          }
        }
        if (formValues[1] != formerComment) {
          try {
            selectedLgt[0].comment = formValues[1];
            USER_API.changeComment("Logement", lgtId, formValues[1]);
          } catch ({ response }) {
            this.errors.push({
              title: "Erreur ",
              msg: response.data,
              snackbar: true
            });
          }
        }
        if (formValues[2] != formerLat || formValues[3] != formerLng) {
          let lat = parseFloat(formValues[2].replace(/,/g, "."));
          let lng = parseFloat(formValues[3].replace(/,/g, "."));
          try {
            selectedLgt[0].gps = lat + "/" + lng;
            USER_API.changeGps("Logement", lgtId, lat + "/" + lng);
          } catch ({ response }) {
            this.errors.push({
              title: "Erreur ",
              msg: response.data,
              snackbar: true
            });
          }
        }
        this.unselectAll();
        Swal.fire({
          title: "OK",
          showConfirmButton: false,
          icon: "success",
          customClass: mySwalClass,
          timerProgressBar: true,
          timer: 1500
        });
      }
    },
    async commentLgt(selectedLgt) {
      if (selectedLgt.length < 21) {
        //let lgtContent = this.list.find(el => (el.id = lgtId));
        Swal.fire({
          title: "Modifier le commentaire",
          input: "text",
          showCancelButton: true,
          confirmButtonText: "Confirmer",
          customClass: mySwalClass
        }).then(async result => {
          if (result.isConfirmed) {
            for await (const el of selectedLgt) {
              try {
                el.comment = result.value;
                USER_API.changeComment("Logement", el.id, result.value);
              } catch ({ response }) {
                this.errors.push({
                  title: "Erreur ",
                  msg: response.data,
                  snackbar: true
                });
              }
            }
            this.unselectAll();
            Swal.fire({
              title: "OK",
              showConfirmButton: false,
              icon: "success",
              customClass: mySwalClass,
              timerProgressBar: true,
              timer: 1500
            });
          }
        });
      } else
        Swal.fire({
          title: "Merci de selectionner<br/>moins de 20 logements.",
          showConfirmButton: false,
          icon: "warning",
          customClass: mySwalClass,
          timerProgressBar: true,
          timer: 1500
        });
    },
    async sendInfo(selectedLgt) {
      Swal.fire({
        title: "Confirmer l'envoi du mail",
        text: "fonction experimentale",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          let data = [];
          let lgtNonR2r = 0;
          selectedLgt.forEach(lgt => {
            if (lgt.states_id == "R2R") {
              data.push(lgt.id);
            } else lgtNonR2r++;
          });
          if (data.length > 0) {
            const dataJson = JSON.stringify(data);
            try {
              OBJECT_API.notifyLgt(this.getSessionToken, dataJson).then(
                response => {
                  const lgt = response.data;
                  const nok = lgt.filter(el => el.result == 0);
                  const ok = lgt.filter(el => el.result != 0);
                  let nokList = "";
                  let okList = "";
                  ok.forEach(
                    el =>
                      (okList =
                        okList + el.lgtId + " -> " + el.result + "<br/>")
                  );
                  nok.forEach(
                    el =>
                      (nokList =
                        nokList + el.lgtId + " -> pas de mail envoyé<br/>")
                  );
                  Swal.fire({
                    title: "Résultat",
                    html: `
                      ${okList}
                      ${okList && nokList ? "<br/><hr/><br/>" : ""}
                      ${nokList}
                      ${
                        lgtNonR2r > 0
                          ? "<br/><hr/><br/>" +
                            lgtNonR2r +
                            " logement(s) non R2R non traité(s)"
                          : ""
                      }
                    `,
                    showCancelButton: false,
                    confirmButtonText: "OK",
                    customClass: mySwalClass
                  });
                }
              );
            } catch ({ response }) {
              this.errors.push({
                title: "Erreur ",
                msg: response.data,
                snackbar: true
              });
            }
          } else
            Swal.fire({
              title: "Seuls les logements R2R<br/>peuvent être notifiés",
              showConfirmButton: false,
              customClass: mySwalClass,
              icon: "warning",
              timerProgressBar: true,
              timer: 2500
            });
          this.unselectAll();
        }
      });
    },
    async statusLgt(selectedLgt) {
      if (selectedLgt.length < 25) {
        const entN4 = this.getActiveEntity.entities_id;
        Swal.fire({
          title: "Confirmer R2R",
          showCancelButton: true,
          confirmButtonText: "Confirmer",
          customClass: mySwalClass
        }).then(result => {
          if (result.isConfirmed) {
            let data = [];
            selectedLgt.forEach(lgt => {
              if (lgt.states_id == "OUT" || lgt.states_id == "1") {
                lgt.states_id = "R2R";
                data.push({
                  itemtype: "PluginGenericobjectLogement",
                  itemtypeID: lgt.id,
                  statut: 2,
                  LgtName: lgt.name,
                  msg: "manuellement",
                  mEntity: entN4
                });
              }
            });
            let dataJson = JSON.stringify(data);
            try {
              if (dataJson.length > 0)
                OBJECT_API.changeAllR2R(this.getSessionToken, dataJson);
            } catch ({ response }) {
              this.errors.push({
                title: "Erreur ",
                msg: response.data,
                snackbar: true
              });
            }
            this.unselectAll();
            if (data.length == 0) {
              Swal.fire({
                title: "Seuls les logements OUT et 1<br/>peuvent passer à R2R",
                showConfirmButton: false,
                customClass: mySwalClass,
                icon: "warning",
                timerProgressBar: true,
                timer: 2500
              });
            } else {
              Swal.fire({
                title: "OK",
                showConfirmButton: false,
                icon: "success",
                customClass: mySwalClass,
                timerProgressBar: true,
                timer: 1500
              });
            }
          }
        });
      } else
        Swal.fire({
          title: "Merci de selectionner<br/>moins de 25 logements.",
          showConfirmButton: false,
          customClass: mySwalClass,
          timerProgressBar: true,
          icon: "warning",
          timer: 1500
        });
    },
    popup() {
      Swal.fire({
        title: "Nouveau fonctionnement",
        text: "Pour modifier : cocher la/les ligne(s) et passer à R2R",
        icon: "info",
        customClass: mySwalClass,
        timerProgressBar: true,
        timer: 3000
      });
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
<style>
/* Modifier la valeur de max-height pour fixer la hauteur des lignes du tableau */

@media only screen and (max-width: 2800px) and (min-width: 800px) {
  .table th {
    vertical-align: middle !important;
    background-color: var(--v-primary-base) !important;
    color: white !important;
  }
}
</style>
