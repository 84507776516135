import { mapGetters, mapActions } from "vuex";
import { loadStates } from "../utils";
import USER_API from "../api/user";

export const LayoutOptions = {
  data() {
    return {
      entitiesLoaded: false
    };
  },
  computed: {
    ...mapGetters([
      "isAuth",
      "getUserProfile",
      "getUserProfileStatus",
      "getEntityLoadedState",
      "getUserData",
      "getSessionLoadState",
      "getProfileLoadingStatus",
      "isGuestUser",
      "getSessionToken"
    ]),
    sessionIsLoading() {
      return (
        this.getSessionLoadState === loadStates.PENDING ||
        this.getProfileLoadingStatus === loadStates.PENDING
      );
    },
    reloadUserProfile() {
      return (
        !this.sessionIsLoading &&
        (!this.getUserProfile || this.getUserProfile.length === 0)
      );
    }
  },
  methods: {
    ...mapActions([
      "loadEntity",
      "loadUserProfile",
      "killSession",
      "loadEntities",
      "logout",
      "guestUser",
      "setUpActiveEntity"
    ]),
    loadEntitiesData() {
      this.loading = true;
      this.loadEntities().finally(() => {
        this.loading = false;
        this.entitiesLoaded = true;
      });
    },
    onLogout() {
      this.killSession().then(() => {
        //this.$router.push("/login");
        this.logout();
      });
    }
  },
  watch: {
    isAuth() {
      if (this.isAuth) {
        //if (!this.$route.meta.directLink) this.$router.push("/assets/index");
      } else {
        this.$router.push("/login");
      }
    },
    sessionIsLoading() {
      if (!this.sessionIsLoading) {
        if (!this.$route.meta.directLink) {
          USER_API.changeActiveEntity().then(() => {
            this.loadEntitiesData();
          });
        } else {
          this.setUpActiveEntity(this.$route.params.id).then(() => {
            this.entitiesLoaded = true;
          });
        }
      }
    }
  }
};
