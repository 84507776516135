import { mapGetters } from "vuex";

export const UserSessionData = {
  data() {
    return {
      list: []
      //loading: false
    };
  },
  computed: {
    ...mapGetters(["getUserProfile", "getEntity", "getActiveEntity", "isAuth"]),
    display: function() {
      return (
        this.getActiveEntity &&
        this.getActiveEntity.id === Number.parseInt(this.$route.params.id)
      );
    }
  }
};
