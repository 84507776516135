import { loadStates } from "../utils";

export const ecran = {
  state: {
    ecranModules: [],
    modulesDataLoadState: loadStates.INIT
  },

  actions: {
    setModule({ commit }, data) {
      commit("setNewModule", data);
    }
  },

  mutations: {
    setNewModule(state, data) {
      state.ecranModules = data;
    },
    setModulesLoadState(state, status) {
      state.modulesDataLoadState = status;
    }
  },
  getters: {
    getEcranModule(state) {
      return state.ecranModules;
    },
    getModulesLoadState: state => state.modulesDataLoadState
  }
};
