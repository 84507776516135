import { loadStates } from "../utils";
const initialState = {
  tickets: [],
  ticketsLoadStatus: loadStates.INITIAL_STATE,
  equipments: [],
  equipmentsLoadStatus: loadStates.INITIAL_STATE,
  rooms: [],
  roomsLoadStatus: loadStates.INITIAL_STATE,
  occupants: [],
  occupantsLoadStatus: loadStates.INITIAL_STATE,
  charges: [],
  chargesLoadStatus: loadStates.INITIAL_STATE,
  travaux: [],
  travauxLoadStatus: loadStates.INITIAL_STATE,
  allTickets: [],
  allTicketsLoadStatus: loadStates.INITIAL_STATE,
  documents: [],
  documentsLoadStatus: loadStates.INITIAL_STATE,
  documentsImages: [],
  reservations: [],
  reservationsLoadStatus: loadStates.INITIAL_STATE,
  ticketsTasks: [],
  ticketsTasksLoadStatus: loadStates.INITIAL_STATE,
  ticketsFields: [],
  allITILSolutions: [],
  allITILSolutionsLoadStatus: loadStates.INITIAL_STATE,
  allITILFollowups: [],
  allITILFollowupsLoadStatus: loadStates.INITIAL_STATE,
  projects: [],
  projectsLoadStatus: loadStates.INITIAL_STATE,
  projectTasks: [],
  projectTaskLoadStatus: loadStates.INITIAL_STATE
};

export const ticket = {
  state: { ...initialState },
  actions: {
    loadTickets({ commit }, tickets) {
      if (tickets && tickets.length > 0) {
        commit("setTickets", tickets);
        commit("setTicketsLoadStatus", loadStates.SUCCESS);
      }
    },
    loadEquipment({ commit }, equipments) {
      if (equipments && equipments.length > 0) {
        commit("setEquipments", equipments);
        commit("setEquipmentsLoadStatus", loadStates.SUCCESS);
      }
    },
    loadRoom({ commit }, rooms) {
      if (rooms && rooms.length > 0) {
        commit("setRooms", rooms);
        commit("setRoomsLoadStatus", loadStates.SUCCESS);
      }
    },
    loadOccupants({ commit }, occupants) {
      if (occupants && occupants.length > 0) {
        commit("setOccupants", occupants);
        commit("setOccupantsLoadStatus", loadStates.SUCCESS);
      }
    },
    loadCharges({ commit }, charges) {
      if (charges && charges.length > 0) {
        commit("setCharges", charges);
      }
      commit("setChargesLoadStatus", loadStates.SUCCESS);
    },
    loadTravaux({ commit }, travaux) {
      if (travaux && travaux.length > 0) {
        commit("setTravaux", travaux);
      }
      commit("setTravauxLoadStatus", loadStates.SUCCESS);
    },
    loadAllTickets({ commit }, allTickets) {
      if (allTickets && allTickets.length > 0) {
        commit("setAllTickets", allTickets);
      }
      commit("setAllTicketsLoadStatus", loadStates.SUCCESS);
    },
    loadDocuments({ commit }, documents) {
      if (documents && documents.length > 0) {
        commit("setDocuments", documents);
      }
      commit("setDocumentsLoadStatus", loadStates.SUCCESS);
    },
    addDocumentsImages({ commit }, documentImage) {
      if (documentImage) {
        commit("addNewImage", documentImage);
      }
    },
    loadReservations({ commit }, reservations) {
      if (reservations) {
        commit("setReservations", reservations);
        commit("setReservationsLoadStatus", loadStates.SUCCESS);
      }
    },
    loadTicketsTasks({ commit }, tasks) {
      if (tasks) {
        commit("setTicketsTasks", tasks);
      }
      commit("setTicketsTasksLoadStatus", loadStates.SUCCESS);
    },
    loadAllITILSolutions({ commit }, allITILSolutions) {
      if (allITILSolutions && allITILSolutions.length > 0) {
        commit("setAllITILSolutions", allITILSolutions);
      }
      commit("setAllITILSolutionsLoadStatus", loadStates.SUCCESS);
    },
    loadAllITILFollowups({ commit }, allITILFollowups) {
      if (allITILFollowups && allITILFollowups.length > 0) {
        commit("setAllITILFollowups", allITILFollowups);
      }
      commit("setAllITILFollowupsLoadStatus", loadStates.SUCCESS);
    },
    loadProject({ commit }, projects) {
      if (projects && projects.length > 0) {
        commit("setProjects", projects);
      }
      commit("setProjectsLoadStatus", loadStates.SUCCESS);
    },
    loadProjectTasks({ commit }, tasks) {
      if (tasks && tasks.length > 0) {
        commit("setProjectsTask", tasks);
      }
      commit("setProjectsTaskLoadStatus", loadStates.SUCCESS);
    },
    resetTickets({ commit }) {
      commit("RESET_INITIAL_STATE");
    }
  },

  mutations: {
    setTickets(state, tickets) {
      state.tickets = tickets;
    },
    setTicketsLoadStatus(state, status) {
      state.ticketsLoadStatus = status;
    },
    setEquipments(state, equipments) {
      state.equipments = equipments;
    },
    setEquipmentsLoadStatus(state, status) {
      state.equipmentsLoadStatus = status;
    },
    setRooms(state, rooms) {
      state.rooms = rooms;
    },
    setRoomsLoadStatus(state, status) {
      state.roomsLoadStatus = status;
    },
    setOccupants(state, list) {
      state.occupants = list;
    },
    setOccupantsLoadStatus(state, status) {
      state.occupantsLoadStatus = status;
    },
    setCharges(state, list) {
      state.charges = list;
    },
    setChargesLoadStatus(state, status) {
      state.chargesLoadStatus = status;
    },
    setTravaux(state, list) {
      state.travaux = list;
    },
    setTravauxLoadStatus(state, status) {
      state.travauxLoadStatus = status;
    },
    setAllTickets(state, list) {
      state.allTickets = list;
    },
    setAllTicketsLoadStatus(state, status) {
      state.allTicketsLoadStatus = status;
    },
    setDocuments(state, list) {
      state.documents = list;
    },
    setDocumentsLoadStatus(state, status) {
      state.documentsLoadStatus = status;
    },
    addNewImage(state, image) {
      state.documentsImages.push(image);
    },
    setReservations(state, list) {
      state.reservations = state.reservations.concat(list);
    },
    setReservationsLoadStatus(state, status) {
      state.reservationsLoadstatus = status;
    },
    setTicketsTasks(state, list) {
      if (list.length > 0) {
        state.ticketsTasks = state.ticketsTasks.concat(list);
      }
    },
    addTicketsTask(state, task) {
      state.ticketsTasks.push(task);
    },
    setTicketsTasksLoadStatus(state, status) {
      state.ticketsTasksLoadStatus = status;
    },
    setTicketsFields(state, list) {
      state.ticketsFields = list;
    },
    setAllITILSolutions(state, list) {
      state.allITILSolutions = list;
    },
    setAllITILSolutionsLoadStatus(state, status) {
      state.allITILSolutionsLoadStatus = status;
    },
    addITILSolution(state, newSolution) {
      state.allITILSolutions.push(newSolution);
    },
    setAllITILFollowups(state, list) {
      state.allITILFollowups = list;
    },
    setAllITILFollowupsLoadStatus(state, status) {
      state.allITILFollowupsLoadStatus = status;
    },
    addITILFollowups(state, newFollowups) {
      state.allITILFollowups.push(newFollowups);
    },
    setProjects(state, projects) {
      state.projects = projects;
    },
    setProjectsLoadStatus(state, status) {
      state.projectsLoadStatus = status;
    },
    setProjectsTask(state, tasks) {
      state.projectTasks = tasks;
    },
    setProjectsTaskLoadStatus(state, status) {
      state.projectsTaskLoadStatus = status;
    },
    setDocumentCategories(state, list) {
      state.documentCategories = list;
    },
    RESET_INITIAL_STATE(state) {
      Object.keys(state).forEach(el => {
        state[el] = initialState[el];
      });
      state.documentsImages = [];
    }
  },

  getters: {
    getTickets(state) {
      return state.tickets;
    },
    getTicketLoadStatus(state) {
      return state.ticketsLoadStatus;
    },
    getEquipments(state) {
      return state.equipments;
    },
    getEquipmentLoadStatus(state) {
      return state.equipmentsLoadStatus;
    },
    getRooms(state) {
      return state.rooms;
    },
    getRoomLoadStatus(state) {
      return state.roomsLoadStatus;
    },

    getCharges(state) {
      return state.charges;
    },
    getChargesLoadStatus(state) {
      return state.chargesLoadStatus;
    },
    getTravaux(state) {
      return state.travaux;
    },
    getTravauxLoadStatus(state) {
      return state.travauxLoadStatus;
    },
    getAllTickets(state) {
      return state.allTickets;
    },
    getAllTicketsLoadStatus(state) {
      return state.allTicketsLoadStatus;
    },
    getDocuments(state) {
      return state.documents;
    },
    getDocumentsLoadStatus(state) {
      return state.documentsLoadStatus;
    },
    getDocumentsImages(state) {
      return state.documentsImages;
    },
    getReservations(state) {
      return state.reservations;
    },
    getReservationsLoadStatus(state) {
      return state.reservationsLoadStatus;
    },
    getTicketsTasks(state) {
      return state.ticketsTasks;
    },
    getTicketsTasksLoadStatus(state) {
      return state.ticketsTasksLoadStatus;
    },
    getAllITILSolutions(state) {
      return state.allITILSolutions;
    },
    getAllITILSolutionsLoadStatus(state) {
      return state.allITILSolutionsLoadStatus;
    },
    getAllITILFollowups(state) {
      return state.allITILFollowups;
    },
    getAllITILFollowupsLoadStatus(state) {
      return state.allITILFollowupsLoadStatus;
    },
    getProjets(state) {
      return state.projects;
    },
    getProjectsLoadStatus(state) {
      return state.projectsLoadStatus;
    },
    getProjetTasks(state) {
      return state.projectTasks;
    },
    getProjectsTaskLoadStatus(state) {
      return state.projectTaskLoadStatus;
    },
    getDocumentCategories(state) {
      return state.documentCategories;
    }
  }
};
