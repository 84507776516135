import { mapActions, mapGetters } from "vuex";
import moment from "../plugins/moment";
import axios from "../plugins/axios";
import { loadStates, getStatus, groupBy, flatten } from "../utils";
import USER_API from "../api/user";

export const TicketsData = {
  data() {
    return {
      tickets: [],
      ticketsFields: []
    };
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getEntity",
      "getActiveEntity",
      "getTickets",
      "getEquipments",
      "getTicketLoadStatus",
      "getEquipmentLoadStatus",
      "getTypeName"
    ]),
    equipments() {
      return this.getEquipments.filter(
        eqp => eqp.entities_id === +this.$route.params.id
      );
    }
  },

  methods: {
    ...mapActions(["loadEquipment", "loadTickets", "emptyItemsFound"]),
    async loadTicketsData() {
      if (this.getTicketLoadStatus === loadStates.INITIAL_STATE) {
        let { data: tickets } = await USER_API.getTickets();
        //this.equipments = (await USER_API.getEquipment()).data;
        //this.loadEquipment(this.equipments);
        await this.getEquipmentsData(tickets);
      } else {
        this.tickets = this.getTickets;
        //this.equipments = this.getEquipments;
      }
      this.$store.commit("setTicketsFields", this.ticketsFields);
    },

    async getTicketItemData(ticketsData) {
      let newTickets = ticketsData.filter(
        ticket =>
          ticket.name.trim().startsWith("{") && ticket.name.trim().endsWith("}")
      );
      newTickets = newTickets.map(ticket => {
        try {
          const name = JSON.parse(ticket.name.trim());
          ticket.orgName = name;
          ticket.name = name.titre;
          ticket.contact_mail = name.mail;
          ticket.contact_tel = name.tel;
          ticket.itemtype = name.itemtype;
          ticket.itemtypeID = name.itemtypeID;
          ticket.resaId = name.resaId;
          return ticket;
        } catch {
          ticket.itemtype = ticket.name;
          ticket.itemtypeID = ticket.name;
          return ticket;
        }
      });
      if (newTickets.length === 0) {
        this.emptyItemsFound();
      }
      newTickets = groupBy(newTickets, x => x.itemtype);
      let data = await Promise.all(
        Object.keys(newTickets).map(async itemType => {
          try {
            await this.loadItemType(itemType);
            let tickets = newTickets[itemType];
            tickets = tickets.map(el => {
              el.equipment = this.getTypeName(itemType, el.itemtypeID);
              return el;
            });

            return tickets;
          } catch (error) {
            return error;
          }
        })
      );

      return flatten(data);
    },

    async getEquipmentsData(ticketsData) {
      const ticketWithItemData = await this.getTicketItemData(ticketsData);

      this.tickets = ticketsData.map(ticket => {
        const ticketCopy = ticket;
        if (
          (ticket.name.trim().startsWith("{") &&
            ticket.name.trim().endsWith("}")) ||
          (ticket.itemtype && ticket.itemtypeID)
        ) {
          ticket = ticketWithItemData.find(el => el.id === ticket.id);
          if (!ticket) {
            ticket = ticketCopy;
            ticket.itemtype = "location";
            ticket.equipment =
              ticket.locations_id === 0
                ? "Sans Equipement"
                : ticket.locations_id;
          }
        } else {
          ticket.itemtype = "location";
          ticket.equipment =
            ticket.locations_id === 0 ? "Sans Equipement" : ticket.locations_id;
        }

        ticket.no_equipment = ticket.locations_id === 0;
        //console.log(ticket.equipment, ticket.locations_id)
        const { date, takeintoaccount_delay_stat } = ticket;
        ticket.attributdate =
          takeintoaccount_delay_stat > 0
            ? moment(date)
                .add(takeintoaccount_delay_stat, "s")
                .format("DD-MM-YYYY HH:mm")
            : "";

        ticket.statusName = getStatus(ticket.status, this.$root.$i18n.locale);
        return ticket;
      });

      const addTickets = this.getAdditionalsTickets(this.tickets);
      addTickets.forEach(tick => {
        this.tickets.push(tick);
      });
      //this.loaded = true;
      //this.loading = false;
      this.loadTickets(this.tickets);
    },
    getAdditionalsTickets(tickets) {
      const addTickets = [];
      this.getEquipments.forEach(equip => {
        const hasTickets = tickets.find(
          tick =>
            tick.equipment === equip.name &&
            tick.entities_id === equip.entities_id
        );
        if (!hasTickets) {
          addTickets.push({
            equipment: equip.name,
            entities_id: equip.entities_id,
            itemtype: "location"
          });
        }
      });
      return addTickets;
    },
    updateTicket(id) {
      const oldTicket = this.tickets.find(ticket => ticket.id === id);
      const oldTicketIndex = this.tickets.findIndex(ticket => ticket.id === id);
      USER_API.getTicket(id).then(({ data }) => {
        data.equipment = oldTicket.equipment;
        this.$set(this.tickets, oldTicketIndex, data);
      });
    },
    updateTicketLastComment(content, ticket_id) {
      const oldTicket = this.tickets.find(ticket => ticket.id === ticket_id);
      const oldTicketIndex = this.tickets.findIndex(
        ticket => ticket.id === ticket_id
      );
      oldTicket.last_comment = content;
      this.$set(this.tickets, oldTicketIndex, oldTicket);
    },
    async getLastComment(ticketID) {
      const followUps = USER_API.getITILFollowup(ticketID);
      const task = USER_API.getTicketTask(ticketID);
      const solutions = USER_API.getITILSolution(ticketID);

      const lastComment = axios.all([followUps, task, solutions]).then(
        axios.spread(
          async (
            { data: comments },
            { data: tasks },
            { data: itSolutions }
          ) => {
            let alldata = comments.concat(tasks).concat(itSolutions);

            let sortedData = alldata.sort((a, b) => {
              if (moment(a.date_creation).isAfter(moment(b.date_creation))) {
                return -1;
              } else if (
                moment(b.date_creation).isAfter(moment(a.date_creation))
              ) {
                return 1;
              } else {
                return 0;
              }
            });

            return sortedData[0] ? sortedData[0].content : null;
          }
        )
      );
      return lastComment;
    }
  }
};
