import USER_API from "../api/user";
import { loadStates } from "../utils";

export const entity = {
  state: {
    entity: [],
    activeEntity: {},
    userEntities: [],
    entityLoadedState: false,
    entityLoadStatus: loadStates.INITIAL_STATE,
    userEntityLoaded: 0,
    treeData: []
  },
  actions: {
    loadEntity({ dispatch, commit }) {
      commit("setEntityLoadStatus", loadStates.PENDING);
      return new Promise((resolve, reject) => {
        USER_API.getUserEntity()
          .then(({ data }) => {
            commit("setEntity", data.myentities);
            dispatch("getUserEntitiesList").then(() => {
              commit("setEntityLoaded", true);
              commit("setEntityLoadStatus", loadStates.SUCCESS);
              resolve("success");
            });
          })
          .catch(error => {
            commit("setEntityLoadStatus", loadStates.ERROR);
            reject(error);
          });
      });
    },
    loadEntities({ commit }) {
      return new Promise((resolve, reject) => {
        USER_API.getEntity()
          .then(({ data }) => {
            const items = data.filter(entity => entity.level >= 2);
            commit("setEntities", items);
            commit("setEntityLoaded", true);
            resolve("success");
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getUserEntitiesList({ state, dispatch }) {
      let payload = {
        index: 0,
        id: state.entity[0].id
      };
      return dispatch("getEntityData", payload);
    },
    getEntityData({ state, commit, dispatch }, payload) {
      return new Promise(resolve => {
        USER_API.setActiveEntity(payload.id).then(() => {
          USER_API.getEntity().then(({ data }) => {
            let newEntity = data.filter(entity => entity.id === payload.id)[0];
            commit("setUserEntity", newEntity);
            commit("setUserEntityLoaded");
            commit("setActiveEntity", newEntity);

            let nextIndex = payload.index + 1;
            if (state.entity.length > nextIndex) {
              dispatch("getEntityData", {
                index: nextIndex,
                id: state.entity[nextIndex].id
              }).then(() => {
                resolve("ok");
              });
            } else {
              resolve("ok");
            }
          });
        });
      });
    },
    setUpActiveEntity({ commit }, id) {
      return new Promise(resolve => {
        USER_API.setActiveEntity(id)
          .then(() => {
            USER_API.getEntity().then(({ data }) => {
              let newEntity = data.filter(entity => entity.id === id)[0];
              if (!newEntity) {
                newEntity = {};
              }
              commit("setUserEntity", newEntity);
              commit("setUserEntityLoaded");
              commit("setActiveEntity", newEntity);
              resolve("ok");
            });
          })
          .catch(() => {
            resolve("ok");
          });
      });
    },
    resetData({ commit }) {
      return new Promise(resolve => {
        commit("resetEntitiesData");
        resolve("done");
      });
    }
  },
  mutations: {
    setEntity(state, entity) {
      state.entity = entity;
    },
    setActiveEntity(state, entity) {
      state.activeEntity = entity;
    },
    setUserEntity(state, entity) {
      state.userEntities.push(entity);
    },
    setEntities(state, entities) {
      state.userEntities = entities;
    },
    setEntityLoaded(state, status) {
      state.entityLoadedState = status;
    },
    setUserEntityLoaded(state) {
      state.userEntityLoaded += 1;
    },
    setEntityLoadStatus(state, status) {
      state.entityLoadStatus = status;
    },
    resetEntitiesData(state) {
      state.entityLoadedState = false;
      state.entity = [];
      state.entityLoadedState = false;
      state.userEntityLoaded = 0;
      state.activeEntity = {};
      state.userEntities = [];
      state.entityLoadStatus = 0;
    },
    setTreeData(state, data) {
      state.treeData = data;
    },
    updateEntityComment(state, payload) {
      let entityIndex = state.treeData.findIndex(el => el.id === payload.id);
      state.treeData[entityIndex].comment = payload.comment;
    }
  },
  getters: {
    getEntity(state) {
      return state.userEntities;
    },
    getActiveEntity(state) {
      return state.activeEntity;
    },
    getEntityLoadedState(state) {
      return state.entityLoadedState;
    },
    getEntityLoadStatus(state) {
      return state.entityLoadStatus;
    },
    getTreeData(state) {
      return state.treeData;
    },
    isActiveEntityLoaded(state) {
      return Object.keys(state.activeEntity).length > 0;
    }
  }
};
