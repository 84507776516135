import en from "vuetify/es5/locale/en";

export default {
  $vuetify: { ...en },
  dashboard: {
    assets_list: "Assets list",
    logout: "Logout",
    default_page_title: "Pilot IMMO portal, here your assets have value",
    help_text:
      "In case of difficulty, please disconnect and then access the application again. Preferably use Chrome browser"
  },
  login: {
    login_to_account: "Please login to access your account",
    connect: "Login",
    user_token: "User token",
    basic_auth: "Basic Auth"
  },
  asset: {
    list_assets: "ASSETS LIST"
  },

  header: {
    theme: "THEME",
    info: "INFO",
    generics: "GENERICS",
    commitment: "COMMITMENT",
    lease: "LEASE",
    financial: "FINANCIAL",
    works: "WORKS",
    perimeter: "Perimeter",
    opening_date: "Opening date",
    date_grant: "Date of grant",
    resolution_date: "Resolution date",
    status: "Status",
    description: "Description",
    category: "Category",
    contact: "Contact",
    email: "Email",
    last_comment: "Last comment",
    user: "User",
    last_acces: "Last access",
    levy: "Loads, Levy and Taxes",
    name: "Name",
    note: "Note",
    creation_date: "Creation date",
    nature: "Nature",
    budget: "Budget N",
    engaged: "Engaged N",
    executed: "Executed N-1",
    commercial_name: "Commercial name",
    surface: "Surface",
    location: "Location",
    signatory: "Signatory of lease",
    lease_start: "Lease Start Date",
    lease_end: "Lease end Date",
    termination_date: "Termination date",
    annual_rent: "Annual Rent € EC Excluding Charges",
    current_balance: "Balance",
    building: "Building",
    stage: "Stage",
    shape: "Shape",
    measurement: "Measurement",
    year_installation: "Year of installation",
    residual_life: "Residual life",
    comment: "comment",
    list_performance: "List of last performance",
    list_reported: "List of the last lift interventions",
    rental: "Rental status",
    work: "Work program",
    tenant_account: "Tenant account statements",
    providers_note: "Note for providers",
    entity: "Entity",
    society: "Society",
    date: "Date",
    budget_n_1: "To budget N + 1",
    realize_last_years: "Completed <5 years",
    image: "Image"
  },

  subHeader: {
    area: "Area",
    tenant: "Tenant",
    location: "Location",
    entry_date: "Entry date",
    release_date: "Release date",
    balance: "Balance",
    rents: "Rents",
    loads: "Loads",
    property_taxes: "Property taxes",
    property_taxes_s: "PT*",
    year_minus_1: "Year Y-1",
    year: "Year Y",
    year_plus_1: "Year Y+1",
    tx_year_minus_1: "Year Y-1",
    tx_year: "Year Y",
    tx_year_plus_1: "Year Y+1",
    processing_tickets: "Tickets in progress",
    resolve_tickets: "Resolved tickets",
    event: "Event D+30",
    processing_incidents: "Incident in progress",
    resolved_incidents: "Resolved incidents"
  },

  tableStatus: {
    new: "New",
    attributed: "Attributed",
    planned: "Planned",
    in_progress: "In progress",
    resolved: "Resolved",
    closed: "Closed",
    filter: "Filter",
    by_name: "By name",
    per_status: "By status",
    by_category: "And/Or by Category",
    searchAll: "Search all range",
    searchName: "Search in the name",
    filter_status: "Filter by status",
    filter_name: "Filter by name",
    access_instructions: "Access instructions",
    filter_perimeter: "Filter per perimeter"
  },

  tableTitle: {
    list_lots: "List of lots",
    list_access: "List of last access",
    list_equipment: "List of equipments",
    accommodation_list: "Accommodation lists",
    list_user: "List of users"
  },

  assetDetails: {
    text1:
      "* If there are commons, for these the values ​​correspond to charges, property taxes and amount of work for the entire building; for the lots the amount corresponds to the quota.",
    text2: "Open the document in a new window",
    text3: "Download the document",
    text4: "Private comment",
    text5: "Complete an ENEDIS request",
    text6: "ENEDIS Certificate",
    text7: "Delete the document"
  },

  index: {
    lease: "Lease",
    account: "Account",
    plan: "Plan",
    rent: "Rents",
    management: "Management",
    procurement: "Procurement",
    dpe: "DPE",
    logo: "Logo",
    photo_corporate: "Photo Corporate",
    today: "Today",
    yesterday: "Yesterday",
    last_days: "Last 30 days",
    documents: "Documents",
    program: "Programme"
  },

  location: {
    text1: "List of locations"
  },

  close: "Close",
  no_equipment: "Without Equipment",
  langs: {
    french: "Français",
    english: "English"
  },
  labels: {
    per_name: "Per Name",
    start_date: "Start Date",
    end_date: "End Date",
    to: "To",
    no_data: "No data",
    apply: "Apply",
    cancel: "Cancel",
    no_category: "Without Category",
    access_review: "Access review"
  },
  editor: {
    send: "Send",
    remake: "Remake",
    cancel: "Cancel",
    horizontal_line: "Horizontal Line",
    bulk: "Bulk",
    underline: "Underline",
    title: "Title",
    sub_title: "SubTitle",
    save: "Save"
  },
  prestation: {
    monthly_benefit_1: "Monthly benefits",
    monthly_benefit_2: "Benefits performed per month"
  },
  calendar: {
    day: "Day",
    week: "Week",
    month: "Month",
    today: "TODAY"
  }
};
