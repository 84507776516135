<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Pilote IMMO</v-toolbar-title>
                <v-spacer />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn :href="source" icon large target="_blank" v-on="on">
                      <v-icon>mdi-code-tags</v-icon>
                    </v-btn>
                  </template>
                  <span>Source</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      large
                      href="https://codepen.io/johnjleider/pen/pMvGQO"
                      target="_blank"
                      v-on="on"
                    >
                      <v-icon>mdi-codepen</v-icon>
                    </v-btn>
                  </template>
                  <span>Codepen</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-alert
                  icon="mdi-shield-lock-outline"
                  prominent
                  text
                  type="info"
                >
                  Donec quam felis, ultricies nec, pellentesque eu, pretium
                  quis, sem. Sed in libero ut nibh placerat accumsan.. Curabitur
                  blandit mollis lacus. Curabitur blandit mollis lacus.
                </v-alert>

                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary">Quitter</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { pusher } from "../plugins/pusher";
import { mapGetters } from "vuex";
export default {
  name: "Event",
  props: {
    source: String
  },
  data() {
    return {
      state: 0
    };
  },
  computed: {
    ...mapGetters(["getUUID"])
  },
  mounted() {
    const { id, userToken, page } = this.$route.params;
    const channel = pusher.subscribe("private-pilote-immo-event");
    channel.bind("pusher:subscription_succeeded", () => {
      this.state = channel.trigger(this.getUUID, {
        id,
        userToken,
        page
      });
    });
  }
};
</script>
