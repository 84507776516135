/* eslint-disable no-unused-vars */
import USER_API from "../api/user";
import { loadStates, gestProfile, adminProfiles } from "../utils";
import { PORTAL_CONFIG } from "../config";

export const user = {
  state: {
    user_token: "",
    glpiID: null,
    userData: {},
    personnal_token: null,
    profile: [],
    profileLoaded: false,
    profileLoadingState: loadStates.INITIAL_STATE,
    guest: false,
    fromEcran: false,
    glpigroups: null,
    users: [],
    usersLoadingState: loadStates.INITIAL_STATE,
    glpiProfile: []
  },

  actions: {
    loadUserProfile({ commit, dispatch }) {
      //dispatch("authUser", 1);
      commit("setProfileLoadingState", loadStates.PENDING);
      return new Promise((resolve, reject) => {
        USER_API.getUserProfile()
          .then(({ data }) => {
            commit("setAuthUserProfile", data);
            commit("setUserProfileLoaded", true);
            dispatch("loadUserId");
            commit("setProfileLoadingState", loadStates.SUCCESS);
          })
          .catch(error => {
            reject(error);
            commit("setAuthUserProfile", {});
            commit("setUserProfileLoaded", false);
            if (error.response.status === 401) {
              dispatch("deleteSession");
              dispatch("logoutUser");
            }
            commit("setProfileLoadingState", loadStates.ERROR);
          });
      });
    },
    loadUserToken({ commit }, user_token) {
      commit("setUserToken", user_token);
    },
    loadUserId({ commit, dispatch }) {
      USER_API.getFullSession().then(({ data }) => {
        let userId = data.session.glpiID;
        if (data.session.glpiprofiles[gestProfile]) {
          let glpiProfile = data.session.glpiprofiles[gestProfile].entities;
          let { glpigroups } = data.session;
          commit("setUserID", userId);
          commit("setGlpiProfiles", glpiProfile);
          commit("setGLPGroups", glpigroups);
          dispatch("loadUserData", userId);
        }
      });
    },
    loadUserData({ commit }, userId) {
      USER_API.getUserData(userId).then(({ data }) => {
        commit("setUserData", data);
      });
    },
    logoutUser({ commit, dispatch }) {
      return new Promise(resolve => {
        commit("removeUserData");
        dispatch("reset").then(() => {
          resolve("done");
        });
      });
    },
    guestUser({ commit }, fromEcran = null) {
      commit("setGuest", true);
      if (fromEcran) {
        commit("setOrigin", true);
      }
    },
    loadUsers({ commit }) {
      commit("setUsersLoadingState", loadStates.PENDING);
      USER_API.getUsers()
        .then(({ data }) => {
          commit("setUsersList", data);
          commit("setUsersLoadingState", loadStates.SUCCESS);
        })
        .catch(() => {
          commit("setUsersLoadingState", loadStates.ERROR);
        });
    }
  },

  mutations: {
    setUserToken(state, data) {
      state.user_token = data;
    },
    setAuthUserProfile(state, data) {
      state.profile = data.myprofiles;
    },
    setUserID(state, userID) {
      state.glpiID = userID;
    },
    setGLPGroups(state, glpigroups) {
      state.glpigroups = glpigroups[0];
    },
    setUserData(state, data) {
      state.userData = data;
      state.personnal_token = data.personal_token;
      localStorage.setItem("user_token", data.api_token);
    },
    setUserPersonalToken(state, token) {
      state.personnal_token = token;
    },
    setUserProfileLoaded(state, status) {
      state.profileLoaded = status;
    },
    setProfileLoadingState(state, status) {
      state.profileLoadingState = status;
    },
    removeUserData(state) {
      state.user_token = "";
      state.profile = [];
      state.profileLoaded = false;
      state.glpiID = null;
      state.userData = {};
      state.personnal_token = null;
      state.guest = false;
    },
    setGuest(state, status) {
      state.guest = status;
    },
    setOrigin(state, origin) {
      state.fromEcran = origin;
    },
    setUsersList(state, users) {
      state.users = users;
    },
    setUsersLoadingState(state, status) {
      state.usersLoadingState = status;
    },
    setGlpiProfiles(state, glpiProfile) {
      state.glpiProfile = glpiProfile;
    }
  },

  getters: {
    getUserProfile(state) {
      return state.profile[0];
    },
    getUserProfileStatus(state) {
      return state.profileLoaded;
    },
    getUserProfiles(state) {
      return state.profile.map(profil => profil.id);
    },
    getUserId(state) {
      return state.glpiID;
    },
    getGLPGroup(state) {
      return state.glpigroups;
    },
    getPersonalToken(state) {
      return state.personnal_token;
    },
    getUserData(state) {
      return state.userData;
    },
    getUserToken(state) {
      return state.userData.api_token;
    },
    getProfileLoadingStatus(state) {
      return state.profileLoadingState;
    },
    isGuestUser(state) {
      return state.guest;
    },
    fromEcran(state) {
      return state.fromEcran;
    },
    isAdminUser(state) {
      return adminProfiles.some(r =>
        state.profile.map(el => el.id).includes(r)
      );
    },
    isPlanifClient() {
      return PORTAL_CONFIG.APP_NAME != "k2t" ? true : false;
    },
    getUsers(state) {
      return state.users;
    },
    getUsersLoadingStatus(state) {
      return state.usersLoadingState;
    },
    getGlpiAcces(state) {
      return state.glpiProfile;
    },
    canAccessEntity(state) {
      return entityID =>
        state.glpiProfile && state.glpiProfile.hasOwnProperty(entityID);
    }
  }
};
