import { TYPE } from "vue-toastification";

export const NOTIFICATION_TYPE = {
  TEXT: "text",
  CUSTOM: "custom"
};
export const CUSTOM_COMPONENT_TYPE = {
  styleOne: 1,
  styleTwo: 2
};

export const getNewReservationNotificationConfig = text => {
  if (
    document.location.href.includes("/view/my/") ||
    text.includes(" > Nouvelle dégradation:")
  ) {
    return "";
  }
  const notificationConfig = {
    color:
      text.includes("lef(s)") || text.includes("INFO ")
        ? TYPE.WARNING
        : TYPE.SUCCESS,
    icon: false,
    notificationType: NOTIFICATION_TYPE.TEXT,
    text,
    title: "Notification",
    timeout: text.includes("lef(s)") ? 7000 : false, //5000
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    rtl: false,
    position: text.includes("lef(s)")
      ? "bottom-right"
      : text.includes("INFO ")
      ? "top-right"
      : "bottom-left"
  };

  return notificationConfig;
};
