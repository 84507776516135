<template>
  <v-app>
    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
    </v-main>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  created() {
    this.loginUser();
  },
  computed: {
    ...mapGetters(["isAuth"])
  },
  methods: {
    ...mapActions(["initSession", "login", "logout", "guestUser"]),
    loginUser: function() {
      if (this.$route.name) {
        if (this.$route.meta.directLink) {
          if (this.isAuth) {
            this.logout().then(() => {
              this.initSession(this.$route.params.userToken, false).then(() => {
                this.login();
                this.guestUser(this.$route.meta.fromEcran);
              });
            });
          } else {
            this.initSession(this.$route.params.userToken, false).then(() => {
              this.login();
              this.guestUser(this.$route.meta.fromEcran);
            });
          }
        } else {
          if (!this.isAuth && !this.$route.name === "login") {
            this.$router.push("/login");
          }
        }
      }
    }
  },
  watch: {
    $route() {
      this.loginUser();
    },
    isAuth() {
      if (!this.isAuth && !this.$route.meta.directLink) {
        this.$router.push("/login");
      }
    }
  }
};
</script>
