/*
	Defines the API route we are using.
*/
var api_url = "",
  front_url = "",
  web_url = "",
  node_url = "",
  serverless_url = "",
  app_token,
  server_web = "",
  app_name = "",
  client_name = "",
  client_data = "",
  urlcourante = "",
  color_primary = "",
  color_drawer = "",
  customer = "",
  token_support = "",
  entity_support = "",
  url_support = "",
  hidden_userId = [],
  customer_dev = "kit",
  token_support_dev = "6NbGh6FlP3U1nRtk40xiZU2gjxMTlp2uCVsPHZ47",
  entity_support_dev = "313",
  newPlateforme = "https://k1.keep.immo",
  default_range = "0-10000";

/*
Foncia   n5szHgkEoxKW9w4mDVfgUnTN2y8l2avxKSTQJoel
KIT      4EVdhezfAKgLvtLjb2HKuyYLBuS4umZIey4f9GVU
K2T      6NbGh6FlP3U1nRtk40xiZU2gjxMTlp2uCVsPHZ47
CIMAPLES mLMzljkCHfDpwjc1xeURFb2gkwJ96MX0AP7TNGWX
MAEVA    65Bstqj05OOshIWL91mGRaeR9jJO5nVSTc5HxClp
ODALYS   otnqp8MA3slTtgiTNkV2p7jSG51R0py8suyGah2b
Depending on the environment, define the API URL.
*/

urlcourante = document.location.href;
//customer = urlcourante.split("//")[1].split(/\/|:/)[0];

switch (process.env.NODE_ENV) {
  case "development":
    switch (customer_dev) {
      case "foncia":
        api_url = "https://pitrack-foncia.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-foncia.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "foncia";
        hidden_userId = [2, 3, 4, 5];
        break;

      case "cimalpes":
        api_url =
          "https://pitrack-cimalpes.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-cimalpes.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "cimalpes";
        hidden_userId = [2, 3, 4, 5];
        break;

      case "maeva":
        api_url = "https://pitrack-maeva.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-maeva.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "maeva";
        hidden_userId = [2, 3, 4, 5];
        break;

      case "odalys":
        api_url = "https://pitrack-odalys.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-odalys.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "odalys";
        hidden_userId = [2, 3, 4, 5];
        break;

      case "store":
        api_url = "https://pitrack-store.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-store.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "store";
        hidden_userId = [2, 3, 4, 5];
        break;

      case "kit":
        api_url = "https://pitrack-kit.pilote.immo/pitrack/apirest.php";
        front_url = "https://pitrack-kit.pilote.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.pilote.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "kit";
        hidden_userId = [2, 3, 4, 5, 9, 13, 14, 83, 120];
        break;

      case "k2t":
        api_url = "https://pitrack-k2t.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-k2t.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "k2t";
        hidden_userId = [2, 3, 4, 5];
        break;

      case "clients":
        api_url = "https://pitrack.pilote.immo/pitrack/apirest.php";
        front_url = "https://pitrack.pilote.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.pilote.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "clients";
        hidden_userId = [2, 3, 4, 5];
        break;

      case "public":
        api_url = "https://pitrack-pub.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-pub.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.pilote.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "public";
        hidden_userId = [2, 3, 4, 5, 9, 13, 14, 83, 120];
        break;

      case "demo":
        api_url = "https://pitrack-dev.pilote.immo/pitrack/apirest.php";
        front_url = "https://pitrack-dev.pilote.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.pilote.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "demo";
        hidden_userId = [2, 3, 4, 5];
        break;

      default:
        api_url = "https://pitrack-kit.pilote.immo/pitrack/apirest.php";
        front_url = "https://pitrack-kit.pilote.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "http://localhost:8080";
        node_url = "http://192.168.12.100:3000";
        serverless_url = "http://localhost:3000";
        app_name = "kit";
        hidden_userId = [2, 3, 4, 5];
        break;
    }
    customer = customer_dev;
    token_support = token_support_dev;
    entity_support = entity_support_dev;
    break;

  case "production":
    customer = urlcourante.split("//")[1].split(".")[0];
    switch (customer) {
      case "foncia":
        api_url = "https://pitrack-foncia.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-foncia.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "https://foncia.pilote.immo";
        node_url = "https://foncia.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://foncia.pilote.immo/api";
        app_name = "foncia";
        token_support = "n5szHgkEoxKW9w4mDVfgUnTN2y8l2avxKSTQJoel";
        entity_support = "311";
        hidden_userId = [2, 3, 4, 5];
        newPlateforme = "https://foncia.keep.immo";
        break;

      case "cimalpes":
        api_url =
          "https://pitrack-cimalpes.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-cimalpes.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "https://cimalpes.pilote.immo";
        node_url = "https://cimalpes.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://cimalpes.pilote.immo/api";
        app_name = "cimalpes";
        token_support = "mLMzljkCHfDpwjc1xeURFb2gkwJ96MX0AP7TNGWX";
        entity_support = "313";
        hidden_userId = [2, 3, 4, 5];
        newPlateforme = "https://cimalpes.keep.immo";
        break;

      case "maeva":
        api_url = "https://pitrack-maeva.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-maeva.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "https://maeva.pilote.immo";
        node_url = "https://maeva.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://maeva.pilote.immo/api";
        app_name = "maeva";
        token_support = "65Bstqj05OOshIWL91mGRaeR9jJO5nVSTc5HxClp";
        entity_support = "316";
        hidden_userId = [2, 3, 4, 5];
        newPlateforme = "https://maeva.keep.immo";
        break;

      case "odalys":
        api_url = "https://pitrack-odalys.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-odalys.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "https://odalys.pilote.immo";
        node_url = "https://odalys.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://odalys.pilote.immo/api";
        app_name = "odalys";
        token_support = "otnqp8MA3slTtgiTNkV2p7jSG51R0py8suyGah2b";
        entity_support = "319";
        hidden_userId = [2, 3, 4, 5];
        newPlateforme = "https://odalys.keep.immo";
        break;

      case "store":
        api_url = "https://pitrack-store.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-store.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "https://store.pilote.immo";
        node_url = "https://store.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://store.pilote.immo/api";
        app_name = "store";
        token_support = "otnqp8MA3slTtgiTNkV2p7jSG51R0py8suyGah2b";
        entity_support = "319";
        hidden_userId = [2, 3, 4, 5];
        break;

      case "kit":
        api_url = "https://pitrack-kit.pilote.immo/pitrack/apirest.php";
        front_url = "https://pitrack-kit.pilote.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.pilote.immo";
        web_url = "https://kit.pilote.immo";
        node_url = "https://kit.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://kit.pilote.immo/api";
        app_name = "kit";
        token_support = "4EVdhezfAKgLvtLjb2HKuyYLBuS4umZIey4f9GVU";
        entity_support = "310";
        hidden_userId = [2, 3, 4, 5, 9, 13, 14, 83, 120];
        newPlateforme = "https://k1.keep.immo";
        break;
      case "k2t":
        api_url = "https://pitrack-k2t.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-k2t.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "https://k2t.pilote.immo";
        node_url = "https://k2t.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://k2t.pilote.immo/api";
        token_support = "6NbGh6FlP3U1nRtk40xiZU2gjxMTlp2uCVsPHZ47";
        app_name = "k2t";
        entity_support = "312";
        hidden_userId = [2, 3, 4, 5];
        newPlateforme = "https://k2.keep.immo";
        break;
      case "public":
        api_url = "https://pitrack-pub.keepintouch.immo/pitrack/apirest.php";
        front_url = "https://pitrack-pub.keepintouch.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.pilote.immo";
        web_url = "https://public.pilote.immo";
        node_url = "https://public.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://public.pilote.immo/api";
        app_name = "public";
        token_support = "E47E7GeljIGq6HLFSSGdAG7fPYKtPg6ObFqXxZ7l";
        entity_support = "317";
        hidden_userId = [2, 3, 4, 5, 9, 13, 14, 83, 120];
        break;
      case "clients":
        api_url = "https://pitrack.pilote.immo/pitrack/apirest.php";
        front_url = "https://pitrack.pilote.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.pilote.immo";
        web_url = "https://clients.pilote.immo";
        node_url = "https://clients.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://clients.pilote.immo/api";
        app_name = "clients";
        break;

      case "demo":
        api_url = "https://pitrack-dev.pilote.immo/pitrack/apirest.php";
        front_url = "https://pitrack-dev.pilote.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.pilote.immo";
        web_url = "https://demo.pilote.immo";
        node_url = "https://demo.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://demo.pilote.immo/api";
        token_support = "BGuGXffDmZjrsmwWacMzZAwYFR0Eu9ZrpC0Rd7yu";
        entity_support = "318";
        hidden_userId = [2, 3, 4, 5];
        app_name = "demo";
        newPlateforme = "https://demo.keep.immo";
        break;

      default:
        api_url = "https://pitrack-kit.pilote.immo/pitrack/apirest.php";
        front_url = "https://pitrack-kit.pilote.immo/pitrack/front";
        app_token = "JrPPyJXURC0j2gpHODxl94QYUxaouJ3LMtIhI2pS";
        server_web = "https://app.keepintouch.immo";
        web_url = "https://kit.pilote.immo";
        node_url = "https://kit.pilote.immo"; //a priori pas utilisé
        serverless_url = "https://kit.pilote.immo/api";
        app_name = "kit";
        token_support = "4EVdhezfAKgLvtLjb2HKuyYLBuS4umZIey4f9GVU";
        entity_support = "310";
        hidden_userId = [2, 3, 4, 5];
        newPlateforme = "https://k1.keep.immo";
        break;
    }
    break;
}

client_name = "main";
client_data = "ecran";
url_support = "https://clients.pilote.immo/view";

/*
  Export the cent URL configuration.
*/
export const PORTAL_CONFIG = {
  API_URL: api_url,
  FRONT_URL: front_url,
  APP_TOKEN: app_token,
  SERVER_WEB: server_web,
  WEB_URL: web_url,
  NODE_SERVER: node_url,
  SERVERLESS_URL: serverless_url,
  APP_NAME: app_name,
  CLIENT_NAME: client_name,
  CLIENT_DATA: client_data,
  CUSTOMER_NAME: customer,
  TOKEN_SUPPORT: token_support,
  URL_SUPPORT: url_support,
  ENTITY_SUPPORT: entity_support,
  HIDDEN_USERID: hidden_userId,
  DEFAULT_RANGE: default_range,
  NEW_PLATEFORME: newPlateforme
};
/*
  other configuration
*/
switch (customer) {
/*   case "foncia":
    color_primary = "#004182";
    color_drawer = "#FFA619";
    break;
  case "cimalpes":
    color_primary = "#5F6C5A";
    color_drawer = "#43443F";
    break;
  case "maeva":
    color_primary = "#44BCC0";
    color_drawer = "#003E52";
    break;
  case "odalys":
    color_primary = "#223C75";
    color_drawer = "#338CFF";
    break;
  case "kit":
  case "public":
    color_primary = "#0072ce";
    color_drawer = "#333F48";
    break;
  case "k2t":
    color_primary = "#b92d32";
    color_drawer = "#333F48";
    break;
  case "clients":
    color_primary = "#44b02a";
    color_drawer = "#333F48";
    break;
  case "demo":
    color_primary = "#E70739";
    color_drawer = "#333F48";
    break; */
  default:
    color_primary = "#727272";
    color_drawer = "#333F48";
    break;
}

export const PORTAL_COLOR = {
  COLOR_PRIMARY: color_primary,
  COLOR_DRAWER: color_drawer
};
