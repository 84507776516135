<template>
  <div class="t-container">
    <div v-if="img" class="img-container">
      <img :src="img" width="70px" alt="" />
    </div>
    <div class="description">
      <h3>{{ title }}</h3>
      <p>{{ text }}</p>
    </div>
    <div></div>
  </div>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    date: {
      type: Date,
      required: false
    },
    color: {
      type: String,
      default: ""
    }
  }
};
</script>
<style>
.t-container {
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.img-container {
  border-radius: 50%;
}

.description {
  margin: 0 20px;
}
p {
  margin-top: 5px;
}
.img-container img {
  border-radius: 50%;
  height: 70px;
}
</style>
