<template>
  <component :is="current">
    <slot />
  </component>
</template>

<script>
import Default from "./layouts/Default";
import Login from "./layouts/Login";
import Ecran from "./layouts/Ecran";
import Event from "./layouts/Event";
import { channel } from "./plugins/pusher";
import { getNewReservationNotificationConfig } from "./utils/notification";
import moment from "./plugins/moment";
import { PORTAL_CONFIG } from "./config";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",

  components: {
    Default,
    Login,
    Ecran,
    Event
  },
  computed: {
    ...mapGetters(["current", "isAuth", "getSessionToken", "canAccessEntity"])
  },
  mounted() {
    localStorage.setItem("last3Refresh", localStorage.getItem("last2Refresh"));
    localStorage.setItem("last2Refresh", localStorage.getItem("lastRefresh"));
    const today = moment().format("YYYY-MM-DD HH:mm:ss");
    localStorage.setItem("lastRefresh", today);
    localStorage.setItem("nbNewIncident", 0);
    localStorage.setItem("nbNewLgt", 0);
    localStorage.setItem("nbNewKey", 0);
    channel.bind(`notification-${PORTAL_CONFIG.APP_NAME}`, event => {
      if (this.canAccessEntity(event.entitiesId)) {
        event.message.includes(" > Nouvelle dégradation:")
          ? this.incrementIncident()
          : event.message.includes("INFO ")
          ? ""
          : event.message.includes("lef(s)")
          ? this.incrementKey()
          : this.incrementLgt();
        const today = moment().format("HH:mm");
        let messageDate = today + " : " + event.message;
        const config = getNewReservationNotificationConfig(messageDate);
        this.$notify(config);
      }
    });
  },

  created() {
    this.setupLang();
    if (
      this.$route.params.user_token ||
      (this.$route.meta && this.$route.meta.directLink)
    ) {
      this.logout();
    }
  },
  methods: {
    ...mapActions([
      "logout",
      "incrementIncident",
      "incrementKey",
      "incrementLgt"
    ]),
    setupLang() {
      const local = this.$store.getters.currentLanguage;
      this.$root.$i18n.locale = local;
    }
  }
};
</script>
<style>
.Vue-Toastification__toast {
  align-items: center;
}

.Vue-Toastification__toast--default.my-custom-toast-class {
  background-color: green;
  align-items: center;
}

/* Applied to the toast body when using regular strings as content */
.Vue-Toastification__toast-body.custom-class-1 {
  font-size: 30px;
}

/* Applied to a wrapper div when using a custom component as content */
.Vue-Toastification__toast-component-body.custom-class-2 {
  width: 100%;
}

.my-button-class {
  opacity: 1 !important;
}

.Vue-Toastification__icon.material-icons {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 6px;
}
</style>
