import axios from "../plugins/axios";
import { PORTAL_CONFIG } from "../config";

let myRange = "0-1000";
if (localStorage.getItem("custom_range")) {
  myRange = localStorage.getItem("custom_range");
} 

export default {
  // eslint-disable-next-line no-unused-vars
  createTicket(payload) {
    return axios.post(`/Ticket?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        ...payload
      }
    });
  },
  linkTicket(payload, ticketID) {
    return axios.post(
      `/Ticket/${ticketID}/Item_Ticket?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          ...payload
        }
      }
    );
  },
  sendComment(ticketID, comment, entity) {
    return axios.post(
      `/Ticket/${ticketID}/ITILFollowup/?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          content: comment,
          itemtype: "Ticket",
          items_id: "" + ticketID,
          entities_id: entity,
          is_recursive: 0
        }
      }
    );
  },
  affectPerson(ticketID, userID) {
    return axios.post(
      `/Ticket/${ticketID}/Ticket_User/?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          tickets_id: ticketID,
          users_id: userID,
          type: 2,
          use_notification: 1
        }
      }
    );
  },
  findAttrib(ticketID, type) {
    return axios.get(
      `/Ticket/${ticketID}/Ticket_User/?app_token=${PORTAL_CONFIG.APP_TOKEN}&searchText[type]=${type}`
    );
  },
  delAttrib(ticketID, ID) {
    return axios.delete(
      `/Ticket/${ticketID}/Ticket_User/${ID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          tickets_id: ticketID,
          id: ID
        }
      }
    );
  },
  cloreIncident(ticketID, comment, entity) {
    return axios.post(
      `/Ticket/${ticketID}/ITILSolution/?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          content: comment,
          itemtype: "Ticket",
          items_id: ticketID,
          entities_id: entity,
          is_recursive: 0
        }
      }
    );
  },
  addTask(ticketID, { start, end, content }, groupID, userID, entity) {
    return axios.post(
      `/Ticket/${ticketID}/TicketTask?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          content: content,
          tickets_id: ticketID,
          begin: start,
          end: end,
          groups_id_tech: groupID,
          users_id_tech: userID,
          entities_id: entity,
          is_recursive: 0
        }
      }
    );
  },
  deleteTask(taskID) {
    return axios.delete(
      `/TicketTask/${taskID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  addImageDoc(uploadImage, originalImg) {
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    let formData = new FormData();
    formData.append(
      "uploadManifest",
      JSON.stringify({
        input: {
          name: "Ticket - Photo",
          _filename: [originalImg.name],
          documentcategories_id: "5",
          comment: "XXX"
        }
      })
    );
    formData.append("filename[0]", uploadImage);
    return axios.post(
      `/Document?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      formData
    );
  },
  attachDocumentToItem(ticketID, documentID) {
    axios.defaults.headers["Content-Type"] = "application/json";

    return axios.post(
      `Ticket/${ticketID}/Document_Item?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          documents_id: documentID,
          itemtype: "Ticket",
          items_id: ticketID
        }
      }
    );
  },

  getDocuments(entityID) {
    return axios.get(
      `/Ticket/${entityID}/Document_Item/?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getDocumentsItems() {
    return axios.get(
      `/Document_Item/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&order=DESC`
    );
  },
  getITILFollowupByID(ITILFollowupID) {
    return axios.get(
      `/ITILFollowup/${ITILFollowupID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getITILSolutionByID(ITILSolutionID) {
    return axios.get(
      `/ITILSolution/${ITILSolutionID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getTaskByID(taskID) {
    return axios.get(
      `/TicketTask/${taskID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  async getReservations(date = null) {
    let url = `/Reservation?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&searchText[is_visible]=1`;
    if (date) {
      url += `&searchText[begin]=${date}`;
    }
    // if (prefix) {
    //  url += `&searchText[comment]=${prefix}`;
    // }
    return axios.get(url);
  },
  getReservationItems() {
    return axios.get(
      `/ReservationItem?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getReservation(reservationId) {
    return axios.get(
      `/Reservation/${reservationId}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getReservationItem(itemtype, items_id) {
    return axios.get(
      `${itemtype}/${items_id}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  updateReservation(reservationId, payload) {
    return axios.put(
      `/Reservation/${reservationId}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          ...payload
        }
      }
    );
  },
  deleteReservation(reservationId) {
    return axios.delete(
      `/Reservation/${reservationId}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  createReservation(payload) {
    return axios.post(`/Reservation?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        ...payload
      }
    });
  },

  createReminder(payload) {
    return axios.post(`/Reminder?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        ...payload
      }
    });
  },
  updateReminder(reminderId, payload) {
    return axios.put(
      `/Reminder/${reminderId}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          ...payload
        }
      }
    );
  },

  getListSearchOptions(entity) {
    return axios.get(
      `/listSearchOptions/${entity}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },

  getDocument(document_id, blob = false) {
    if (blob) {
      return axios(
        `/Document/${document_id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
        {
          responseType: "blob" // important,
        }
      );
    } else {
      return axios.get(
        `/Document/${document_id}?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
      );
    }
  },
  getMyEntity(entityID) {
    return axios.get(`Entity/${entityID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  getGroup(entitiesID) {
    return axios.get(
      `Group?searchText[entities_id]=${entitiesID}&app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getTasks() {
    return axios.get(
      `/TicketTask/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getItemType(itemType) {
    return axios.get(
      `/${itemType}?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getItemTypeByID(itemType, itemTypeID) {
    return axios.get(
      `/${itemType}/${itemTypeID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getTicketUser(ticketID) {
    return axios.get(
      `Ticket/${ticketID}/Ticket_User/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  deleteTicketUser(ticketID, userID) {
    return axios.delete(
      `Ticket/${ticketID}/Ticket_User/${userID}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  changeName(ticketID, item_type, itemtype_ID, Name, contactMail, contactTel) {
    return axios.put(
      `/Ticket/${ticketID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          name: JSON.stringify({
            itemtype: item_type,
            itemtypeID: itemtype_ID,
            titre: Name,
            mail: contactMail,
            tel: contactTel
          })
        }
      }
    );
  },
  pingEcran(url) {
    return fetch(url, {
      headers: {
        "Content-Type": "text/calendar; charset=utf-8"
      }
    });
  },
  addTicketClos(ecran) {
    return axios.post(`/Ticket?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        name: "Ecran",
        requesttypes_id: "7",
        content: ecran,
        type: "1",
        itilcategories_id: "3",
        status: "6"
      }
    });
  },
  changeDescription(ticketID, Content) {
    return axios.put(
      `/Ticket/${ticketID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: { content: Content }
      }
    );
  },
  changeCategories(ticketID, Category) {
    const cats = [
      ["Exploitation", 1],
      ["Commissionnement", 2],
      ["I feel Good", 3],
      ["Permanent", 4],
      ["Prestation", 5],
      ["Acces", 6],
      ["Equipement", 7],
      ["Public", 8]
    ];
    const Category_id = cats.find(element => element[0] === Category)[1];
    return axios.put(
      `/Ticket/${ticketID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: { itilcategories_id: Category_id }
      }
    );
  },
  changeType(ticketID, Type) {
    return axios.put(
      `/Ticket/${ticketID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: { type: Type }
      }
    );
  },
  changeStatus(ticketID, Status) {
    return axios.put(
      `/Ticket/${ticketID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: { status: Status }
      }
    );
  }
};
