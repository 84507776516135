<template>
  <v-col cols="12">
    <v-card class="mt-6" flat min-width="300px">
      <v-card v-if="isCartoMap" flat width="98%" class="mb-6">
        <map-view :list="locationList"></map-view>
      </v-card>
      <v-card-title class="headline display-1">
        {{ $t("location.text1") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('tableStatus.searchAll')"
          single-line
          hide-details
          class="mt-n2"
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="list"
          item-key="name"
          class="table is_bordered"
          :loading="loading"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @click:row="clicked"
        >
          <template v-slot:item="{ item }">
            <c-row
              :item="item"
              @click="clicked(item)"
              :headers="headers"
              rtl
              ignore-null-val
            ></c-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { UserSessionData } from "../../mixins/userData";
import MapView from "../../components/Map";
import USER_API from "../../api/user";
import { mapGetters } from "vuex";
import { PORTAL_CONFIG } from "../../config";

export default {
  components: { MapView },
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      search: "",
      isCartoMap: true,
      locationList: [],
      loading: false,
      headers: [
        {
          text: this.$t("header.name"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("header.stage"),
          align: "left",
          sortable: false,
          value: "state"
        },
        {
          text: this.$t("header.note"),
          align: "left",
          sortable: false,
          value: "comment"
        },
        {
          text: this.$t("header.building"),
          align: "left",
          sortable: false,
          value: "building"
        }
      ]
    };
  },
  mixins: [UserSessionData],
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken"
    ])
  },
  methods: {
    clicked: function(item) {
      window.open(
        PORTAL_CONFIG.SERVER_WEB +
          "/" +
          PORTAL_CONFIG.APP_NAME +
          "/" +
          PORTAL_CONFIG.CLIENT_NAME +
          "/itemtype.php?user=" +
          this.getUserToken +
          "&ept=" +
          item.room +
          "&entity=" +
          this.$route.params.id
      );
    },
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        USER_API.getLocations()
          .then(response => {
            this.list = response.data;
            const size = this.list.length;
            this.locationList = [];
            let nbgps = 0;
            //let clientName = this.getActiveEntity.completename
            //  .split(">")[1]
            //  .trim();
            if (size > 0) {
              //pour mettre les points sur la carte
              //CCA pas parfait car la carte ne se met pas à jour quand on change de lot
              for (let i = 0; i < size; i++) {
                if (this.list[i].latitude) {
                  nbgps = nbgps + 1;
                  this.locationList.push({
                    name:
                      isNaN(this.list[i].name) === true
                        ? this.list[i].name.toUpperCase()
                        : this.list[i].name,
                    operationnel: this.list[i].operationnel,
                    /* si souci avec les markers, il faudrait peut etre utiliser cela
                    operationnel:
                      this.list[i].operationnel !== undefined
                        ? this.list[i].operationnel
                        : "NA",*/
                    lat: this.list[i].latitude,
                    lng: this.list[i].longitude,
                    link:
                      PORTAL_CONFIG.SERVER_WEB +
                      "/" +
                      PORTAL_CONFIG.APP_NAME +
                      "/" +
                      PORTAL_CONFIG.CLIENT_NAME +
                      "/itemtype.php?user=" +
                      this.getUserToken +
                      "&ept=" +
                      this.list[i].room +
                      "&entity=" +
                      this.$route.params.id
                  });
                } else {
                  this.isCartoMap = false;
                }
              }
              //pour écrire le tableau
              for (let i = 0; i < size; i++) {
                if (isNaN(this.list[i].name) === true) {
                  this.list[i].name = this.list[i].name.toUpperCase();
                }
              }
            }
            if (nbgps === 0) {
              this.isCartoMap = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
