import Notification from "../components/notification/Notification.vue";
import NotificationType2 from "../components/notification/NotificationType2.vue";
import CloseBtn from "../components/CloseBtn.vue";
import { POSITION } from "vue-toastification";
import {
  NOTIFICATION_TYPE,
  CUSTOM_COMPONENT_TYPE
} from "../utils/notification";
import { TYPE } from "vue-toastification";

const install = Vue => {
  function getToastType(type) {
    switch (type) {
      case TYPE.SUCCESS:
        return Vue.$toast.success;
      case TYPE.ERROR:
        return Vue.$toast.error;
      case TYPE.WARNING:
        return Vue.$toast.warning;
      default:
        return Vue.$toast.info;
    }
  }

  const getPosition = chosenPosition => {
    const findPositionValue = Object.values(POSITION).some(
      position => position === chosenPosition
    );
    return findPositionValue ? chosenPosition : POSITION.TOP_RIGHT;
  };

  Vue.prototype.$notify = function(payload) {
    const toastMethod = getToastType(payload.color);
    if (payload.notificationType === NOTIFICATION_TYPE.TEXT) {
      toastMethod(payload.text, {
        ...payload,
        position: getPosition(payload.position)
      });
    } else {
      if (payload.customType == CUSTOM_COMPONENT_TYPE.styleOne) {
        const content = {
          component: Notification,
          props: {
            img: payload.img,
            title: payload.title,
            text: payload.text
          }
        };
        toastMethod(content, {
          icon: false,
          ...payload,
          position: getPosition(payload.position)
        });
      } else if (payload.customType == CUSTOM_COMPONENT_TYPE.styleTwo) {
        const content = {
          component: NotificationType2,
          props: {
            title: payload.title,
            text: payload.description
          }
        };

        toastMethod(content, {
          icon: {
            iconClass: "material-icons", // Optional
            iconChildren: payload.icon, // Optional
            iconTag: "span" // Optional
          },
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"],
          closeButton: CloseBtn,
          closeButtonClassName: "my-button-class",
          ...payload,
          position: getPosition(payload.position)
        });
      }
    }
  };
};

export default install;
