import Vue from "vue";
export const assets = {
  state: {
    assets: [],
    assetsKey: 1
  },

  actions: {
    addAsset({ commit }, asset) {
      commit("setNewAsset", asset);
    },
    updateAsset({ commit }, payload) {
      commit("updateOldAsset", payload);
    },
    resetAssets({ commit }) {
      commit("RESET_ASSETS");
    }
  },

  mutations: {
    setNewAsset(state, asset) {
      const index = state.assets.findIndex(el => el.id === asset.id);
      if (index > -1) {
        Vue.set(state.assets, asset, index);
      } else {
        state.assets.push(asset);
      }
    },
    updateOldAsset(state, payload) {
      Vue.set(state.assets, payload.entity, payload.index);
    },
    RESET_ASSETS(state) {
      state.assets = [];
    },
    SET_KEY(state) {
      state.assetsKey++;
    }
  },
  getters: {
    getAssets(state) {
      return state.assets;
    },
    assetsKey: state => state.assetsKey
  }
};
