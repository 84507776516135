import Vue from "vue";
import Vuex from "vuex";
import axios from "../plugins/axios";

Vue.use(Vuex);

import { user } from "../modules/user";
import { session } from "../modules/session";
import { auth } from "../modules/auth";
import { entity } from "../modules/entity";
import { ticket } from "../modules/ticket";
import { assets } from "../modules/assets";
import { ecran } from "../modules/ecran";
import { notification } from "../modules/notification";
import { CreateUUID } from "../utils";
import { itemType } from "../modules/itemtype";

export default new Vuex.Store({
  struct: true,
  state: {
    isInitialised: false,
    home: true,
    uuid: CreateUUID(),
    lang: localStorage.getItem("_lang") ? localStorage.getItem("_lang") : "fr"
  },
  mutations: {
    showHome: state => (state.home = true),
    hideHome: state => (state.home = false),
    setLang: (state, lang) => {
      state.lang = lang;
      localStorage.setItem("_lang", lang);
    }
  },
  actions: {
    reset({ dispatch }) {
      dispatch("resetAssets");
      dispatch("resetData");
      dispatch("resetItemData");
      dispatch("resetTickets");
    },
    changeLang({ commit }, lang) {
      commit("setLang", lang);
    }
  },
  getters: {
    current: state => {
      if (!state.auth.isAuth && state.user.guest && !state.user.fromEcran) {
        return "login";
      }
      axios.defaults.headers.common["Session-Token"] =
        state.session.session_token;

      if (state.user.fromEcran) {
        return "ecran";
      }

      if (state.user.fromEvent) {
        return event;
      }
      return "default";
    },
    getUUID: state => state.uuid,
    currentLanguage: state => state.lang
  },
  modules: {
    user,
    session,
    auth,
    entity,
    ticket,
    assets,
    ecran,
    itemType,
    notification
  }
});
