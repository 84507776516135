<template>
  <div class="clock-container">
    <div class="clock d-flex align-center">
      <ul class="pa-0">
        <template v-if="isMobile">
          <li id="hours">
            <v-chip
              label
              large
              class="title"
              color="blue-grey lighten-3"
              text-color="#333F48"
            >
              {{ hour }}
              <li class="pointblink">:</li>
              {{ min }}
            </v-chip>
          </li>
        </template>
        <template v-else>
          <li id="hours">
            <v-chip
              label
              large
              class="title"
              color="blue-grey lighten-3"
              text-color="#333F48"
            >
              {{ hour }}
            </v-chip>
          </li>
          <li class="point">:</li>
          <li id="min">
            <v-chip
              label
              large
              class="title"
              color="blue-grey lighten-3"
              text-color="#333F48"
            >
              {{ min }}
            </v-chip>
          </li>
          <li class="point">:</li>
          <li id="sec">
            <v-chip label large class="title" color="blue-grey darken-1">
              {{ sec }}
            </v-chip>
          </li>
        </template>
      </ul>
      <h3>{{ date }}</h3>
    </div>
  </div>
</template>

<script>
import moment from "../../plugins/moment";
import { convertToTitleCase } from "../../utils";
export default {
  name: "Clock",
  data() {
    return {
      hour: "",
      min: "",
      sec: "",
      date: "",
      week: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      dayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ]
    };
  },
  computed: {
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.tv
      );
    }
  },
  mounted() {
    // Create a newDate() object
    let newDate = new Date();
    // Extract the current date from Date object
    newDate.setDate(newDate.getDate());
    // Output the day, date, month and year
    if (
      this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lh
    ) {
      this.date = convertToTitleCase(moment().format("dd DD MMM"));
    } else {
      this.date = convertToTitleCase(moment().format("dddd DD MMMM"));
    }

    setInterval(() => {
      // Create a newDate() object and extract the seconds of the current time on the visitor's
      let seconds = new Date().getSeconds();
      // Add a leading zero to seconds value
      this.sec = (seconds < 10 ? "0" : "") + seconds;
    }, 1000);

    setInterval(() => {
      // Create a newDate() object and extract the minutes of the current time on the visitor's
      let minutes = new Date().getMinutes();
      // Add a leading zero to the minutes value
      this.min = (minutes < 10 ? "0" : "") + minutes;
    }, 1000);

    setInterval(() => {
      // Create a newDate() object and extract the hours of the current time on the visitor's
      let hours = new Date().getHours();
      // Add a leading zero to the hours value
      this.hour = (hours < 10 ? "0" : "") + hours;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
/* If you want you can use font-face */
@font-face {
  font-family: "BebasNeueRegular";
  src: url("../../assets/fonts/BebasNeue-webfont.eot");
  src: url("../../assets/fonts/BebasNeue-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/BebasNeue-webfont.woff") format("woff"),
    url("../../assets/fonts/BebasNeue-webfont.ttf") format("truetype"),
    url("../../assets/fonts/BebasNeue-webfont.svg#BebasNeueRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.clock-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
}

.clock {
  width: 100%;
  padding: 10px;
  color: #fff;
}

#Date {
  font-family: "BebasNeueRegular", Arial, Helvetica, sans-serif;
  text-align: center;
  //text-shadow: 0 0 5px #00c6ff;
}

ul {
  width: 50%;
  //margin: 0 auto;
  padding: 0;
  list-style: none;
  text-align: start;
}
ul li {
  display: inline;
  text-align: center;
  font-family: "BebasNeueRegular", Arial, Helvetica, sans-serif;
  //text-shadow: 0 0 5px #00c6ff;
}

.point {
  position: relative;
  //-moz-animation: mymove 1s ease infinite;
  //-webkit-animation: mymove 1s ease infinite;
  padding-left: 10px;
  padding-right: 10px;
}

.pointblink {
  position: relative;
  -moz-animation: mymove 1s ease infinite;
  -webkit-animation: mymove 1s ease infinite;
  padding-left: 10px;
  padding-right: 10px;
}

@-webkit-keyframes mymove {
  0% {
    opacity: 1;
    text-shadow: 0 0 20px #00c6ff;
  }
  50% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 20px #00c6ff;
  }
}

@-moz-keyframes mymove {
  0% {
    opacity: 1;
    text-shadow: 0 0 20px #00c6ff;
  }
  50% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 20px #00c6ff;
  }
}
</style>
