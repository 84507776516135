import axios from "../plugins/axios";
import { PORTAL_CONFIG } from "../config";

let myRange =PORTAL_CONFIG.DEFAULT_RANGE;
if (localStorage.getItem("custom_range")) {
  myRange = localStorage.getItem("custom_range");
} 

export default {
  // eslint-disable-next-line no-unused-vars
  getUserProfile: function() {
    return axios.get(`/getMyProfiles/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  getUsersProfile: function(id, ent) {
    return axios.get(
      `/Profile_User/?app_token=${PORTAL_CONFIG.APP_TOKEN}&searchText[entities_id]=${ent}&searchText[users_id]=${id}&range=${myRange}`
    );
  },
  getUsersProfileId: function(id, profile, ent) {
    return axios.get(
      `/Profile_User/?app_token=${PORTAL_CONFIG.APP_TOKEN}&searchText[entities_id]=${ent}&searchText[users_id]=${id}&searchText[profiles_id]=${profile}&range=${myRange}`
    );
  },
  addProfile(userId, profileId, ent) {
    return axios.post(`/Profile_User/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        users_id: userId,
        profiles_id: profileId,
        entities_id: ent,
        is_recursive: 1
      }
    });
  },
  delProfile(id) {
    return axios.delete(
      `/Profile_User/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getUserEmail(id) {
    return axios.get(
      `/User/${id}/UserEmail?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  postUserEmail(userId, eMail) {
    return axios.post(
      `/User/${userId}/UserEmail?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          users_id: userId,
          is_default: 1,
          is_dynamic: 0,
          email: eMail
        }
      }
    );
  },
  putUserEmail(id, eMail) {
    return axios.put(`/UserEmail/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        email: eMail
      }
    });
  },
  getFullSession() {
    return axios.get(`/getFullSession/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  getUserData(id) {
    return axios.get(
      `/User/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false`
    );
  },
  getEntity: function() {
    return axios.get(
      `/Entity/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getUserEntity: function() {
    return axios.get(`/getMyEntities/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  setActiveEntity(entity_id) {
    return axios.post(
      `changeActiveEntities/?app_token=${PORTAL_CONFIG.APP_TOKEN}&entities_id=${entity_id}`
    );
  },
  occupants() {
    return axios.get(
      `/PluginGenericobjectOccupant/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getCharge() {
    return axios.get(
      `/PluginGenericobjectCharge/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getTravaux() {
    return axios.get(
      `/PluginGenericobjectTravauxger/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getDocuments(entity_id) {
    return axios.get(
      `/Entity/${entity_id}/Document_Item/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getDocument(document_id, blob = false) {
    if (blob) {
      return axios(
        `/Document/${document_id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
        {
          responseType: "blob" // important,
        }
      );
    } else {
      return axios.get(
        `/Document/${document_id}?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
      );
    }
  },
  downloadDocument(item) {
    axios(
      `/Document/${item.id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
      {
        responseType: "blob" // important,
      }
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item.filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
  downloadSearchDocument(item) {
    axios(
      `/Document/${item[2]}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
      {
        responseType: "blob" // important,
      }
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item.filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
  openDocument(item) {
    axios(
      `/Document/${item.id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
      {
        responseType: "blob" // important,
      }
    ).then(response => {
      const url = URL.createObjectURL(response.data);
      window.open(
        url,
        item.filename,
        "menubar=no,status=no,scrollbars=no,width=800,height=800"
      );
    });
  },
  openSearchDocument(item) {
    axios(
      `/Document/${item[2]}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&alt=media`,
      {
        responseType: "blob" // important,
      }
    ).then(response => {
      const url = URL.createObjectURL(response.data);
      window.open(
        url,
        item.filename,
        "menubar=no,status=no,scrollbars=no,width=800,height=800"
      );
    });
  },
  updateDoc(item) {
    return axios.put(
      `/Document/${item[2]}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          documentcategories_id: 21
        }
      }
    );
  },
  updateTouristDoc(item) {
    return axios.put(
      `/Document/${item.id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          documentcategories_id: 21
        }
      }
    );
  },

  getEquipment(equipmentNom) {
    return axios.get(
      `/PluginGenericobject${equipmentNom}/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getEquipmentTicketItems(equipmentId, equipmentNom) {
    return axios.get(
      `/PluginGenericobject${equipmentNom}/${equipmentId}/Item_Ticket?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getRoom() {
    return axios.get(
      `/PluginGenericobjectRoom/?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  getRoomTicketItems(roomId) {
    return axios.get(
      `/PluginGenericobjectRoom/${roomId}/Item_Ticket?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getAllTickets() {
    return axios.get(
      `/Ticket/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&order=DESC&get_hateoas=false`
    );
  },
  getTickets() {
    return axios.get(
      `/Ticket/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&order=DESC&expand_dropdowns=true&get_hateoas=false`
    );
  },
  getTicket(ticketID, expand = false) {
    let url = `/Ticket/${ticketID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`;
    if (expand) {
      url += "&expand_dropdowns=true";
    }
    return axios.get(url);
  },
  getTicketsUsers() {
    return axios.get(
      `/Ticket_User/?app_token=${PORTAL_CONFIG.APP_TOKEN}&searchText[type]=2&range=${myRange}`
    );
  },
  getITILSolution(ticketID) {
    return axios.get(
      `/Ticket/${ticketID}/ITILSolution/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false`
    );
  },
  getITILFollowup(ticketID) {
    return axios.get(
      `/Ticket/${ticketID}/ITILFollowup/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false`
    );
  },
  getTicketTask(ticketID) {
    return axios.get(
      `/Ticket/${ticketID}/TicketTask/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false`
    );
  },
  getPhotos() {
    return axios.get(
      `/Document/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false&searchText[documentcategories_id]=2`
    );
  },
  changeActiveEntity() {
    return axios.post(
      `changeActiveEntities/?app_token=${PORTAL_CONFIG.APP_TOKEN}`
    );
  },
  switchActiveEntity(id) {
    return axios.post(
      `changeActiveEntities/?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: {
          entities_id: id,
          is_recursive: true
        }
      }
    );
  },
  loadDoc() {
    return axios.get(
      `Document/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&expand_dropdowns=true`
    );
  },
  loadSpecificDoc(cat) {
    return axios.get(
      `Document/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&expand_dropdowns=true&searchText[documentcategories_id]=${cat}`
    );
  },
  searchDoc() {
    return axios.get(
      `/search/Document/?app_token=${PORTAL_CONFIG.APP_TOKEN}` +
        `&criteria[0][link]=OR&criteria[0][field]=7&criteria[0][searchtype]=equals&criteria[0][value]=20` +
        `&criteria[1][link]=OR&criteria[1][field]=7&criteria[1][searchtype]=equals&criteria[1][value]=29` +
        `&criteria[2][link]=OR&criteria[2][field]=7&criteria[2][searchtype]=equals&criteria[2][value]=17` +
        `&criteria[3][link]=OR&criteria[3][field]=7&criteria[3][searchtype]=equals&criteria[3][value]=16` +
        `&criteria[4][link]=OR&criteria[4][field]=7&criteria[4][searchtype]=equals&criteria[4][value]=22` +
        `&criteria[5][link]=OR&criteria[5][field]=7&criteria[5][searchtype]=equals&criteria[5][value]=23` +
        `&criteria[6][link]=OR&criteria[6][field]=7&criteria[6][searchtype]=equals&criteria[6][value]=25` +
        `&criteria[7][link]=OR&criteria[7][field]=7&criteria[7][searchtype]=equals&criteria[7][value]=26` +
        `&criteria[8][link]=OR&criteria[8][field]=7&criteria[8][searchtype]=equals&criteria[8][value]=27` +
        `&criteria[9][link]=OR&criteria[9][field]=7&criteria[9][searchtype]=equals&criteria[9][value]=14` +
        `&range=${myRange}&expand_dropdowns=true`
    );
  },
  getActiveEntity() {
    return axios.get(`/Entity/?app_token=${PORTAL_CONFIG.APP_TOKEN}`);
  },
  updateComment(id, comment) {
    return axios.put(`/Entity/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        comment
      }
    });
  },
  changeConf(ID, Tel, MailOk, conf) {
    return axios.put(`/Entity/${ID}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        website: JSON.stringify(conf),
        phonenumber: Tel,
        email: MailOk
      }
    });
  },
  getAllITILSolutions() {
    return axios.get(
      `/ITILSolution/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false`
    );
  },
  getAllITILFollowups() {
    return axios.get(
      `/ITILFollowup/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false`
    );
  },
  getLocations() {
    return axios.get(
      `/Location/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false`
    );
  },
  addNote(entityID, note) {
    return axios.post(`/Notepad/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        content: note,
        itemtype: "Entity",
        items_id: entityID
      }
    });
  },
  getEntityNote(entityID) {
    return axios.get(
      `/Entity/${entityID}/Notepad/?app_token=${PORTAL_CONFIG.APP_TOKEN}&order=DESC&range=${myRange}`
    );
  },
  getProjects() {
    return axios.get(
      `/Project/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getProjectTask(projectID) {
    return axios.get(
      `/${projectID}/ProjectTask/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getAllProjectTask() {
    return axios.get(
      `/ProjectTask/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getDocumentCategories() {
    return axios.get(
      `/DocumentCategory/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}`
    );
  },
  getUsers() {
    return axios.get(
      `/User/?app_token=${PORTAL_CONFIG.APP_TOKEN}&range=${myRange}&get_hateoas=false`
    );
  },
  deactivateUser(id, Active) {
    return axios.put(`/User/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        is_active: Active
      }
    });
  },
  mdpUser(id, pwd1, pwd2) {
    return axios.put(`/User/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        password: pwd1,
        password2: pwd2
      }
    });
  },
  commentUser(id, commentaire) {
    return axios.put(`/User/${id}/?app_token=${PORTAL_CONFIG.APP_TOKEN}`, {
      input: {
        comment: commentaire
      }
    });
  },
  changeComment(equipmentNom, equipmentID, Content) {
    return axios.put(
      `/PluginGenericobject${equipmentNom}/${equipmentID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: { comment: Content }
      }
    );
  },
  changeName(equipmentNom, equipmentID, Content) {
    return axios.put(
      `/PluginGenericobject${equipmentNom}/${equipmentID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: { name: Content }
      }
    );
  },
  changeGps(equipmentNom, equipmentID, Content) {
    return axios.put(
      `/PluginGenericobject${equipmentNom}/${equipmentID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: { gps: Content }
      }
    );
  },
  changeStates(equipmentNom, equipmentID, Content) {
    return axios.put(
      `/PluginGenericobject${equipmentNom}/${equipmentID}?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      {
        input: { states_id: Content }
      }
    );
  },
  addImageDoc(uploadImage, originalImg, name, categorie) {
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    let formData = new FormData();
    formData.append(
      "uploadManifest",
      JSON.stringify({
        input: {
          name: name,
          _filename: [originalImg.name],
          documentcategories_id: categorie,
          comment: "XXX"
        }
      })
    );
    formData.append("filename[0]", uploadImage);
    return axios.post(
      `/Document?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      formData
    );
  },
  addNewDocument(document, name, categorie, recursive) {
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    let formData = new FormData();
    formData.append(
      "uploadManifest",
      JSON.stringify({
        input: {
          name: name,
          _filename: [document.name],
          documentcategories_id: categorie,
          comment: "XXX",
          is_recursive: recursive
        }
      })
    );
    formData.append("filename[0]", document);

    return axios.post(
      `/Document?app_token=${PORTAL_CONFIG.APP_TOKEN}`,
      formData
    );
  }
};
