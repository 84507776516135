import Swal from "sweetalert2";
import "../assets/swal.css";

export const mySwalClass = {
  title: "swal-title",
  container: "swal-title",
  confirmButton: "swal-ok",
  cancelButton: "swal-cancel",
  timerProgressBar: "swal-bar"
  /*
  container: '...',
  popup: '...',
  header: '...',
  closeButton: '...',
  icon: '...',
  image: '...',
  htmlContainer: '...',
  input: '...',
  inputLabel: '...',
  validationMessage: '...',
  actions: '...',denyButton: '...',
  cancelButton: '...',
  loader: '...',
  footer: '....',
  timerProgressBar: '....',*/
};

export const showConfirmAlert = (
  title,
  text,
  denyButtonText,
  confirmedCallback,
  deniedCallback,
  cancelCallback
) => {
  Swal.fire({
    title,
    text,
    icon: "warning",
    showCancelButton: true,
    showDenyButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    denyButtonColor: "orange",
    confirmButtonText: "Toutes",
    denyButtonText,
    cancelButtonText: "Annuler"
  }).then(async resp => {
    if (resp.isConfirmed) {
      confirmedCallback();
    } else if (resp.isDenied) {
      deniedCallback();
    } else {
      cancelCallback();
    }
  });
};
