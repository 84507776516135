<template>
  <div>
    <button class="cls-btn">
      close
    </button>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.cls-btn {
  background-color: red;
  padding: 8px 10px;
  color: white;
  font-size: 19px;
  border-radius: 9px;
}
</style>
